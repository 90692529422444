import './MainMenu.css'
import React, { useEffect, useContext } from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';
import { SearchContext } from '../../contexts/SearchContext.jsx';
import HistoryComponent from '../History/History.jsx';
import ButtonAction from '../Buttons/ButtonAction.jsx';
import ButtonState from '../Buttons/ButtonState.jsx';
import ButtonJump from '../Buttons/ButtonJump.jsx';

function MainMenu() {
	const db = useDatabase();
	const { openSearch } = useContext(SearchContext);

	function blurActiveElement() {
		if (document.activeElement && document.activeElement.blur) {
			document.activeElement.blur();
		}
	}

	const handleSave = () => {
		// Blur any selected field and give it a moment to send its update to the db
		blurActiveElement();
		db.save().then(() => {
			console.log('Data saved successfully');
		}).catch((error) => {
			console.error('Error saving data', error);
		});
	};

	function toggleEditMode()
	{
		db.setEditMode(!db.editMode);
	}

	
	// Generic Button component which can be reused
	const Button = ({ onClick, children }) => {
		return (
			<button className='button-main-menu' onClick={onClick}>
				{children}
			</button>
		);
	};

	function globalSearch()
	{
		openSearch();
	}

	// Render the MainMenu with a Save button
	return (
		<div id='main-menu' className='main-menu-bar'>
			{/* Reuse this button for different functionalities by passing different onClick handlers */}
			<ButtonState onClick={toggleEditMode} label={db.editMode ?"Editing":"Viewing"}/>
			{db.editMode && <ButtonAction onClick={handleSave} label={"Save"}/>}
			<HistoryComponent/>
			<ButtonJump onClick={globalSearch} label={"Find..."}/>
		</div>
	);
}

export default MainMenu;
