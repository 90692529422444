import './Entry.css';
import React, { useEffect, useState } from 'react';
import EntryHeaderComponent from './Entry.Heading.jsx';
import { useDatabase } from '../../DatabaseProvider.jsx';
import { categoryList } from '../../PropertyDefs.js';
import scrollToNote from './scrollToNote.js';
import EntryBodyComponent from './Entry.Body.jsx';
import addNote from './addNote.js';
import referredList from './referredList.js';

function EntryComponent({currentEntryPath}) 
{
	const db = useDatabase();
    const selectedCategoryID = currentEntryPath.categoryID;
    const selectedEntryID = currentEntryPath.entryID;
    const selectedNoteID = currentEntryPath.noteID;
	
	const selectedEntry = db.data[selectedCategoryID][selectedEntryID];
	
	const [expandOverride, setExpandOverride] = useState("exp");

	useEffect(() => {
		// Let it send out a single pulse of "exp" or "col" for expand or collapse
		// Then revert it to null so the same operation can be called later to re-apply 
		// exp or col to any items that might have been manually toggled in the interim
		setExpandOverride(null);
	}, [expandOverride])

	// Include noteID in the currentEntryPath to scroll to that note once the body is built
	useEffect(() => {
		const cleanup = scrollToNote(selectedNoteID);
		// Return the cleanup function so it's called on unmount or before re-running the effect
		return cleanup;
	}, [selectedNoteID]);
	
	if (!selectedEntry)
	{
		// console.warn("Can't find entry",currentEntryPath);
		return null;
	}

	// Each category array has a certain type that defines what edit fields are used
	// Plus user-friendly name and tooltip for the entire category
	const categoryDef = categoryList.find(o=>o.id===currentEntryPath.categoryID);
	if (!categoryDef)
	{
		console.error("No category definition for",selectedCategoryID);
		return;
	}

	function addNoteToDB()
	{
		// Must pass reference to the db
		addNote(db,currentEntryPath);
	}
	
	function expandAllNotes()
	{
		setExpandOverride('exp');
	}

	function collapseAllNotes()
	{
		setExpandOverride('col');
	}

	function duplicateEntry()
	{
		db.duplicateEntry(selectedEntry,selectedCategoryID);
	}

	function deleteEntry()
	{
		db.deleteEntry(selectedEntry,selectedCategoryID);
	}

	// All entries share a common format: 
	// A collection of unique properties that fill a header
	// An array of Notes that fills the rest of the panel vertically, 
	// allowing scrolling in that section while leaving the header stationary
	const key = selectedCategoryID+selectedEntryID;
	const keyHeader = key+"header";
	const keyNotes = key+"notes";
	return (
		<div key={key}className="entry-display">
			<div key={keyHeader} className='entry-header'>
				<EntryHeaderComponent 
					categoryDef={categoryDef} 
					currentEntryPath={currentEntryPath}
					addNote={addNoteToDB}
					expandAll={expandAllNotes}
					collapseAll={collapseAllNotes} 
					duplicateEntry={duplicateEntry}
					deleteEntry={deleteEntry}
					referredList={referredList(db,categoryList,selectedEntryID)}
				/>
			</div>
			<div key={keyNotes} className='entry-notes'>
				<EntryBodyComponent 
					categoryDef={categoryDef} 
					currentEntryPath={currentEntryPath} 
					expandOverride={expandOverride}
				/>
			</div>
		</div>
	);
}
export default EntryComponent;


// The database is a collection of category objects, each of which contains a list of entries. 
// The categoryList indicates the name of each, including a "type" to indicate how to display an entry for each category
// categoryList example
// {
//		 "id": "events", // The name of the object on the root of database (db.events)
//		 "name": "Events", // User friendly label for UI
//		 "type": "eventDefProperties", // Array of definitions in propertyDefs that indicate which fields are editable and how
//		 "tooltip": "Descriptions of narrative quests, historical lore, rules that govern the world, etc."
// },

// Each category has a definition for which contents within it are editable.

// categoryList.type is a lookup into propertyDefs
// Example propertyDefs[categoryList.type]
// For this currentEntry, we know that we will be editing the name, eventType, desc and notes section
// Each type has its own unique editor (text, eventTypes dropdown, etc)
// [
// 	{ "id":"name", "name":"Event", "type":"text", "class":"heading1", "refresh":true, "tooltip":"Name of event"},
// 	{ "id":"eventType", "name":"Type", "type":"eventTypes", "tooltip":"Type of event", "filterBy":true},
// 	{ "id":"desc", "placeholder":"Summary", "type":"text", "tooltip":"Summary"},
// 	{ "id":"notes", "name":"Notes", "type":"entries", "tooltip":"Array of attached note entries"}
// ]
