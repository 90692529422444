
export function GetDateAgo(currentDay, eventDay, abbreviated = false)
{
	// const varDays = this.ttrpg?.database?.vars.find(o => o.id === "daysPerYear");
	// const varDaysValue = varDays?.value;
	const daysPerYear = 360;// varDaysValue ? Number(varDaysValue) : 365;
	// console.log(varDaysValue,daysPerYear)
	const days = currentDay - eventDay;
	const weeks = Math.round(days / 7);
	const months = Math.round(days / 30);
	const years = Math.round(days / daysPerYear);
	// Mathematically perfect, the orbit on Tarka completes every 360 days, dividing the year into 12 months of 30 days.
	var num;
	var timeframe;
	var includeDays = false;

	if (days < 7) {
		num = days;
		timeframe = abbreviated ? " da" : days > 1 ? " days ago" : " day ago";
	}
	else if (weeks < 8) {
		num = weeks;
		timeframe = abbreviated ? " wa" : weeks > 1 ? " weeks ago" : " week ago";
		includeDays = true;
	}
	else if (months < 12) {
		num = months;
		timeframe = abbreviated ? " ma" : months > 1 ? " months ago" : " month ago";
		includeDays = true;
	}
	else {
		num = years;
		timeframe = abbreviated ? " ya" : years > 1 ? " years ago" : " year ago";
		includeDays = true;
	}
	var output = num.toLocaleString() + timeframe;
	if (includeDays && !abbreviated) { output += " (" + days + " days)"; }
	return output;
}