import { useDatabase } from '../../DatabaseProvider.jsx';

export function generateDropdownOptions({propertyDefArray,categoryID,qualifyPath})
{
	const db = useDatabase();

	let dropdownOptions;
	if (propertyDefArray)
	{
		// Just send the whole thing since it's already an array
		return propertyDefArray;
	}

	if (categoryID) {
		const categoryObject = db.data[categoryID];
		if (!categoryObject)
		{
			console.log("No categoryObject",categoryID)
		}
		else
		if (qualifyPath) {
			dropdownOptions = Object.values(categoryObject).filter(item => {
				// Use reduce to navigate through the nested properties
				const qualifyingValue = qualifyPath.reduce((acc, key) => {
					return (acc && acc[key] !== undefined) ? acc[key] : undefined;
				}, item);
	
				// Return true if the qualifying value exists and is not 0
				return qualifyingValue !== undefined && qualifyingValue !== 0;
			});
		} else {
			dropdownOptions = Object.values(categoryObject);
		}
	}
	
	return dropdownOptions;
}
