import { generateUniqueID } from "../../UniqueID";

export default function addNote(db,currentEntryPath)
{
	const newEntryNotes = {...db.data[currentEntryPath.categoryID][currentEntryPath.entryID]["notes"]}

	// Create a blank note to add to the selected entry
	const newNote = {id:generateUniqueID()}
	newNote.name = "";
	newNote.txt = "";
	newNote.mentions = [];
	// img path and mentions array are added only when needed
	
	// Add it to the list
	newEntryNotes[newNote.id] = newNote;
	// And put it at the end of the order
	newEntryNotes.noteOrder.ids.push(newNote.id);

	// Build a path out of the elements that got us up to this place. Updating the database will traverse the db using this path
	const path = 
	[
		currentEntryPath.categoryID,
		currentEntryPath.entryID,
		"notes"
	];

	// And the entry's note object contains a noteOrder object with an ids array to include this new ID
	db.updateDB(path, newEntryNotes);
} 

