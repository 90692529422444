import { useState, useEffect } from 'react';
import { openDB } from 'idb';

const VisibilityController = (orderInfo, currentCat) => {
    const [visibilityInfo, setVisibilityInfo] = useState([]);

    const databaseName = 'allCategoriesState';
    const storeName = 'entryList';

    // Function to initialize the database
    const initDB = async () => {
        const idb = await openDB(databaseName, 1, {
            upgrade(idb) {
                if (!idb.objectStoreNames.contains(storeName)) {
                    idb.createObjectStore(storeName);
                }
            },
        });
        return idb;
    };

    // Function to fetch hidden status from the store
    const fetchHiddenStatus = async (categoryName) => {
        const idb = await initDB();
        const tx = idb.transaction([storeName], 'readonly');
        const store = tx.objectStore(storeName);
        const hiddenIds = await store.get(categoryName);
        await tx.done;
        return hiddenIds || [];
    };

    // Function to update visibility based on the stored data
	const updateVisibility = async () => {
		// Fetch the list of hidden IDs
		const hiddenIds = await fetchHiddenStatus(currentCat);
		setVisibilityInfo(hiddenIds);
	};
	
    useEffect(() => {
		if (orderInfo)
		{
			updateVisibility();
		}
    }, [orderInfo, currentCat]); // Rerun when orderInfo or currentCat changes

	const toggleVisibility = async (entryID, override = null) => {
		const idb = await initDB();
		const transaction = idb.transaction([storeName], 'readwrite');
		const store = transaction.objectStore(storeName);
	
		// Fetch the current list of hidden IDs
		let hiddenIds = await store.get(currentCat) || [];
	
		// Check if the entryID is already in the list
		const index = hiddenIds.indexOf(entryID);
	
		if (override !== null) {
			// If override is provided, add or remove based on its value
			if (override && index === -1) {
				hiddenIds.push(entryID); // Add if override is true and entryID is not in the list
			} else if (!override && index > -1) {
				hiddenIds.splice(index, 1); // Remove if override is false and entryID is in the list
			}
		} else {
			// If no override, toggle the visibility
			if (index > -1) {
				hiddenIds.splice(index, 1); // Remove if it's in the list
			} else {
				hiddenIds.push(entryID); // Add if it's not in the list
			}
		}
	
		// Update the store with the new list
		store.put(hiddenIds, currentCat);
	
		// Handle the completion of the transaction
		transaction.oncomplete = async () => {
			await updateVisibility(); // Refresh visibilityInfo after the transaction completes
		};
	
		// Handle errors
		transaction.onerror = (event) => {
			console.error('Transaction error:', event.target.error);
		};
	};
	
    const collapseAll = async () => {
		const idb = await initDB();
		const ids = orderInfo
			.filter(item => item.id !== 'rootOrder') // Exclude items with id 'rootOrder'
			.map(item => item.id);
	
		const transaction = idb.transaction([storeName], 'readwrite');
		const store = transaction.objectStore(storeName);
	
		// Directly store the ids array with currentCat as the key
		store.put(ids, currentCat);
	
		transaction.oncomplete = async () => {
			await updateVisibility(); // Update the visibility info after the transaction completes
		};

		// Handle errors
		transaction.onerror = (event) => {
			console.error('Transaction error:', event.target.error);
		};
	};	

	const expandAll = async () => {
		const idb = await initDB();
	
		const transaction = idb.transaction([storeName], 'readwrite');
		const store = transaction.objectStore(storeName);
	
		// Store an empty array for the current category to signify that no entries are hidden
		store.put([], currentCat);
	
		// Handle the completion of the transaction
		transaction.oncomplete = async () => {
			await updateVisibility();
		};
	
		// Handle errors
		transaction.onerror = (event) => {
			console.error('Transaction error:', event.target.error);
		};
	};	

    return { visibilityInfo, collapseAll, expandAll, toggleVisibility };
};

export default VisibilityController;
