let delayRef;

export const delayedSetIsDragging = (setIsDragging, setIs) => {
    if (delayRef) {
        clearTimeout(delayRef);
    }
    delayRef = setTimeout(() => {
        setIsDragging(setIs);
    }, 10);
};

export const handleDragStart = (e, entry, setIsDragging) => {
    e.dataTransfer.setData("text/plain", entry.id);
    delayedSetIsDragging(setIsDragging, true);
};

export const handleDragEnd = (e, setIsDragging, setHoveredElement) => {
    delayedSetIsDragging(setIsDragging, false);
    setHoveredElement(null);
};

export const handleDropOnSpacer = (e, targetEntry, setHoveredElement, relocateEntry) => {
	e.preventDefault();
	const droppedEntryId = e.dataTransfer.getData("text/plain");
	delayedSetIsDragging(false);
	// console.log("null hover handleDropOnSpacer")
	setHoveredElement(null);
	const instruction = {
		src:droppedEntryId,
		dest:targetEntry.id,
		type:"spacer"
	}
	relocateEntry(instruction);
};

export const handleDropOnButton = (e, targetEntry, setHoveredElement, relocateEntry) => {
	e.preventDefault();
	const droppedEntryId = e.dataTransfer.getData("text/plain");
	delayedSetIsDragging(false);
	// console.log("null hover handleDropOnButton")
	setHoveredElement(null);
	const instruction = {
		src:droppedEntryId,
		dest:targetEntry.id,
		type:"button"
	}
	relocateEntry(instruction);
};

export const handleDragOverSpacer = (e) => {
	e.preventDefault();
};

export const handleDragEnterSpacer = (e, targetEntry, setHoveredElement) => {
	const hoverInstruction = {type:"spacer",id:targetEntry.id};
	// console.log("handleDragEnterSpacer",targetEntry?.id,hoverInstruction)
	setHoveredElement(hoverInstruction);
};

export const handleDragLeaveSpacer = (e, targetEntry, setHoveredElement) => {
	// console.log("handleDragLeaveSpacer",targetEntry?.id)
};	

export const handleDragEnterButton = (e, targetEntry, setHoveredElement) => {
	const hoverInstruction = {type:"button",id:targetEntry.id};
	// console.log("handleDragEnterButton",targetEntry?.id,hoverInstruction)
	setHoveredElement(hoverInstruction);
};

export const handleDragLeaveButton = (e, targetEntry, setHoveredElement) => {
	// console.log("dragLeave button",targetEntry.id)
};

export function relocateEntry(instruction,db,currentCat,smartLog)
{
	function getEntryName(id)
	{
		if(!id) return null;
		if (id==="rootOrder") return "rootOrder";
		return db.data[currentCat][id].name;
	}

	let updatedListManagers = JSON.parse(JSON.stringify(db.data.listManagers));

	const srcEntry = updatedListManagers[currentCat].find(entry => entry.id === instruction.src);
	const destEntry = updatedListManagers[currentCat].find(entry => entry.id === instruction.dest);

	if (srcEntry.id === destEntry.id) {
		// Nothing happens if dropped in the same place
		return;
	}

	let fullSteps = "Moving " + getEntryName(instruction.src) + " (" + instruction.src + ") to " + getEntryName(instruction.dest) + " (" + instruction.dest + ")";

	// Remove src from its former parent's children list
	const formerParent = updatedListManagers[currentCat].find(entry => entry.id === srcEntry.parentID);
	if (formerParent && formerParent.children) {
		const index = formerParent.children.indexOf(srcEntry.id);
		if (index > -1) {
			formerParent.children.splice(index, 1);
		}
	}

	// Assign new parent based on the type of relocation
	srcEntry.parentID = instruction.type === "spacer" ? destEntry.parentID : destEntry.id;

	// Find the new parent and add src as its child
	const newParent = updatedListManagers[currentCat].find(entry => entry.id === srcEntry.parentID);
	if (newParent) {
		if (!newParent.children) {
			newParent.children = [];
		}
		// Insert srcEntry at the correct position
		if (instruction.type === "spacer") {
			const destIndex = newParent.children.indexOf(instruction.dest);
			newParent.children.splice(destIndex + 1, 0, srcEntry.id); // Insert srcEntry after destEntry
		} else {
			newParent.children.push(srcEntry.id); // Add to the end if it's a "button" type relocation
		}
	} else {
		console.warn("Can't figure out new parent for", getEntryName(srcEntry.id,db), " (should be", srcEntry.parentID, ")");
	}

	smartLog({cat:"listMan"},"EntryList:relocateEntry",fullSteps);
	db.updateListManagers(updatedListManagers);
}
