import React, { createContext, useState } from 'react';

// Step 1: Creating the context
export const RollHistoryContext = createContext();

// Step 2: Creating the context provider component
export const RollHistoryProvider = ({ children }) => {
    const [rollHistory, setRollHistory] = useState([]);

    // Function to add a new roll to the history
    const addRoll = roll => {
        setRollHistory(currentHistory => [...currentHistory, roll]);
    };

    const clearRollHistory = () => {
        setRollHistory([]);
    }

    return (
        <RollHistoryContext.Provider value={{ 
                rollHistory, 
                addRoll,
                clearRollHistory 
            }}>
            {children}
        </RollHistoryContext.Provider>
    );
};
