import './Entry.css';
import React, { useEffect, useRef } from 'react';
import { propertyDefs } from '../../PropertyDefs.js';
import EditorFactory from './EditorFactory.jsx';
import { useDatabase } from '../../DatabaseProvider.jsx';
import ButtonExpand from '../Buttons/ButtonExpand.jsx';
import ButtonDelete from '../Buttons/ButtonDelete.jsx';
import ButtonAdd from '../Buttons/ButtonAdd.jsx';

// Static header bar at the top of the entry window. Title, summary and a few other bits of detail.
// At the bottom of the header is the control bar for the notes section (+Notes, Collapse/Expand)
// Below the header is the scrolling list of notes.

function EntryHeaderComponent({ 
								categoryDef, 
								currentEntryPath, 
								addNote, 
								expandAll, 
								collapseAll, 
								deleteEntry,
								duplicateEntry,
								referredList
							}) 
{
	const isEncounter = categoryDef.id==="encounters";
	
	const db = useDatabase();

	const selectedCategoryID = currentEntryPath.categoryID;
    const selectedEntryID = currentEntryPath.entryID;

	const typeOfProperties = categoryDef.type;
	const proptype = propertyDefs[typeOfProperties];
	if (!proptype)
	{
		console.error("Can identify propertyDef",typeOfProperties);
		return;
	}

	const categoryID = useRef(categoryDef.id);

	useEffect(()=> {
		categoryID.current = categoryDef.id;
	}, [categoryDef])

	// All contents
	let contents = [];
	// Contents injected into sharedRows
	let sharedRows = {}; 

	const key = selectedCategoryID + selectedEntryID;
	
	proptype.forEach((propDef, index) => {
		// Special case for encounters: only bother with the name. All the rest is the specialized Encounter manager in the body
		if (isEncounter && propDef.id !== "name") { return; }
	
		const elementKey = `${key}-${propDef.id || index}`;
		const element = (
			<EditorFactory 
				key={elementKey}
				propDef={propDef} 
				index={index} 
				currentEntryPath={currentEntryPath}
			/>
		);
	
		if (propDef.sharedRow) 
		{
			// If sharedRow is specified, find or create the array for this sharedRow
			if (!sharedRows[propDef.sharedRow]) 
			{
				sharedRows[propDef.sharedRow] = [];
			}
			sharedRows[propDef.sharedRow].push(element);
		} else {
			let extraClass = "";
			switch (propDef.id)
			{
				case "rolls":
					extraClass = "extra-frame-roll";
					break;
				case "statblock":
					extraClass = "extra-frame-statblock";
					break;
				default:
					break;
			}

			const fullClass = "editor-field-row "+extraClass;
			// If there's no specified sharedRow, it means this element should have its own row
			contents.push(<div key={elementKey} id={elementKey} className={fullClass}>{element}</div>);
		}
	});

	function jumpTo(instruction)
	{
		db.setCurrentEntry(instruction);
	}

	function buildReferenceList(list)
	{
		if (!list.length || list.length == 0) { return null; }
		return (
			<div className='reference-list'>
				<div className='reference-title'>{"Referred by:"}</div>
				{list.map((entry,index) => (
					<button 
						key={entry.name+index}
						className='jump-link' 
						onClick={()=>jumpTo(entry.link)}>{entry.name}
					</button>
				))}
			</div>
		)
		return 
	}
	
	Object.keys(sharedRows).forEach((rowContent, idx) => {
		const rowKey = `${key}-shared-${rowContent}-${idx}`;
		const rowElements = sharedRows[rowContent];
		contents.push(
			<div key={rowKey} id={rowKey} className="entry-shared-row">
				{rowElements}
			</div>
		);
	});

	return (
		<>
		{contents}
		<div key={key+"controls"} className='entry-control-row'>
			{db.editMode && !isEncounter && <button className={'button-add'} onClick={addNote}>+ Note</button>}
			{!isEncounter && <ButtonExpand onClick={expandAll} label={"Expand ⇣"}/>}
			{!isEncounter && <ButtonExpand onClick={collapseAll} label={"Collapse ⇡"}/>}
			{db.editMode && <ButtonAdd onClick={duplicateEntry} label={"Duplicate Entry"}/>}
			{db.editMode && <ButtonDelete onClick={deleteEntry} label={"Delete Entry"}/>}
			{buildReferenceList(referredList)}
		</div>
		</>
		
	)
}

export default EntryHeaderComponent;