import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useDatabase } from '../../DatabaseProvider';
import './EditText.css'

const EditTextComponent = ({ simpleCallback, simpleValue, pathObj, property, propertyDef, label, onUpdateValue, type, className }) => 
{
	// console.log({ pathObj, property, propertyDef, label, onUpdateValue })
	
	const db = useDatabase();
	const [textValue, setTextValue] = useState('');
	const escPressed = useRef(false);
	const textAreaRef = useRef(null);

	const initValue = useRef();

	// Adjust the height of the textarea to fit the content
	const adjustTextAreaHeight = () => 
	{
		if (!textAreaRef.current) {
			return;
		}
		const textarea = textAreaRef.current;
		textarea.style.height = 'auto'; // Reset the height to 'auto' to get the correct scrollHeight
		const padding = (parseInt(window.getComputedStyle(textarea).paddingTop, 10) || 0) +
						(parseInt(window.getComputedStyle(textarea).paddingBottom, 10) || 0);
		// Set to smallest first to ensure we only grow tall enough to fit
		textarea.style.height = "0px";
		textarea.style.height = `${textarea.scrollHeight + padding}px`;
	};

	useLayoutEffect(() => 
	{
		adjustTextAreaHeight();
	}, [textValue]);

	useEffect(() => 
	{
		// Simple text editing doesn't send the result back into the db automatically along the path
		if (!pathObj) { return; }
		const entryData = db.getPropertyByPath(pathObj.path);
		if (!entryData)
		{
			console.error("Can't find entryData:",pathObj);
			return;
		}
		if (entryData[property] != null)
		{
			initValue.current = entryData[property];
		}
		else
		{
			initValue.current = '';	
		}
		setTextValue(initValue.current);
		escPressed.current = false;
	}, [pathObj, db.data, property]);

	useEffect(() => 
	{
		initValue.current = simpleValue;	
		escPressed.current = false;
	}, [simpleValue]);

	const handleKeyDown = (event) => 
	{
		if (event.key === 'Escape') {
			setTextValue(initValue.current); // Reset to initial value on Esc
			escPressed.current = true; // Indicate that Esc was pressed
			textAreaRef.current.blur(); // Optionally blur the textarea
			adjustTextAreaHeight();
		}
	};

	const handleChange = (event) => 
	{
		if (simpleCallback) { simpleCallback(event.target.value); }
		setTextValue(event.target.value);
	};

	const handleBlur = () => 
	{
		if (textValue !== initValue.current && !escPressed.current) 
		{
			// Include the property we just set in the path
			if (simpleCallback)
			{
				// Just send it back. We're editing some compound element, 
				// not ready to submit to the database yet
				simpleCallback(textValue);
			}
			else
			{
				onUpdateValue(pathObj,textValue);
			}
		}
		escPressed.current = false;
	};
	
	const placeholder = propertyDef.placeholder || propertyDef.tooltip || propertyDef.name || '';
	const key = `${propertyDef.id}index`;

	adjustTextAreaHeight();

	const combinedClassName = `${className||''} edittext-textarea ${propertyDef.class || ''}`;

	const editable = (
		<textarea
			ref={textAreaRef}
			placeholder={placeholder}
			value={textValue}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			onBlur={handleBlur}
			className={combinedClassName}
		/>
	)

	const editableNumber = (
		<input
			type={"number"}
			placeholder={placeholder}
			value={textValue}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			onBlur={handleBlur}
			className={combinedClassName}
		/>
	)

	function parseAsciiText(text) 
	{
		if (!text) { return; }

		// Don't filter numbers
		if (typeof(text)=="number")
		{
			return text;
		}

		// Replace newlines with <br>
		text = text.replace(/\n/g, "<br>");

		// Replace [[ ]] with DM lines, intended to be read verbatim to the players
		text = text.replace(/\[\[(.*?)\]\]/g, '<div class="dmQuote">$1</div>');

		// Replace ## with Header type <span style="font-size: larger;"></span>
		text = text.replace(/##(.*?)##/g, '<span style="font-size: 22px;">$1</span>');
	  
		// Replace _ with <em></em>
		text = text.replace(/_(.*?)_/g, '<span class="dmEmphasis">$1</span>');
	  
		// Replace ** with <strong></strong>
		text = text.replace(/\*\*(.*?)\*\*/g, '<span class="dmBold">$1</span>');

		// Replace * with <strong></strong>
		text = text.replace(/\*(.*?)\*/g, '<i style="background-color: inherit;">$1</i>');

		// Text within [] is dim
		text = text.replace(/\[([^\]]+)\]/g, '<span class="dmNote">[$1]</span>');

		// > at start of line injects &emsp; space
		text = text.replace(/<br>> /g, "<br>&emsp;");

		// --- becomes horizontal rule
		text = text.replace(/---/g,"<hr>");
	  
		return text;
	}

	// TODO: SANITIZE textValue before setting as innerHTML
	// parsing out markup to apply span styles 
	const readonly = (
		<div 
			dangerouslySetInnerHTML={{ __html: parseAsciiText(textValue) }}
			className={combinedClassName}
		/>
	)

	return (
		<>
			{label && <div>{label}</div>}
			{db.editMode && (type === "number" ? editableNumber : editable)}
			{!db.editMode && (textValue && readonly)}
		</>
	);
};

export default EditTextComponent;
