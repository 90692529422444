import React, { useState } from 'react';
import axios from 'axios';

const LoginForm = ({ onLoginSuccess }) => {
	const [password, setPassword] = useState('');

	const handleLogin = async (event) => {
		event.preventDefault();
		try {
			const response = await axios.post('/api/login', { password });
			if (response.data.message === 'Logged in successfully') {
				onLoginSuccess();
			}
		} catch (error) {
			alert('Login failed: ' + (error.response.data.message || 'Unknown error'));
		}
	};

	return (
		<div className='full-screen-blank'>
			<form onSubmit={handleLogin}>
				<input
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					placeholder="Enter password"
					required
				/>
				<button type="submit">Login</button>
			</form>
		</div>
	);
};

export default LoginForm;
