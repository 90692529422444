import './ImagePicker.css';
import React, { useState, useEffect, useRef } from 'react';

const API_URL = `${import.meta.env.VITE_API_URL}`;

const ImagePickerComponent = ({ onChoose }) => {
    const filterInputRef = useRef(null);
    const [filter, setFilter] = useState("");
    const [filelist, setFilelist] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_URL}/api/imageList`);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const jsonData = await response.json();
                setFilelist(jsonData);
                setSearchResults(jsonData);
            } catch (error) {
                console.error('Error fetching JSON data:', error);
                setError('Failed to load image list.');
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (filterInputRef.current) {
            filterInputRef.current.focus();
        }

        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                if (filter !== "") {
                    setFilter("");
                } else {
                    onChoose(null);
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [filter, onChoose]);

    const performSearch = (newFilter) => {
        setFilter(newFilter);
        const lowerCaseFilter = newFilter.toLowerCase();
        const filteredResults = filelist.filter(file =>
            file.toLowerCase().includes(lowerCaseFilter)
        );
        setSearchResults(filteredResults);
    };

    function pickImage(fileName) {
        onChoose(fileName);
    }

    const renderSearchOutput = () => {
        if (error) {
            return <div className="error-message">{error}</div>;
        }

        return (
            <div className="image-picker-search-results">
                {searchResults.map((fileName, index) => (
                    <button 
                        key={index}
                        className="image-picker-button"
                        onClick={() => pickImage(fileName)}>
                        {fileName}
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div className="image-picker-frame">
            <div className="image-picker-filter-and-close">
                <input
                    ref={filterInputRef}
                    className="image-picker-filter-input"
                    type="text"
                    placeholder="Filter..."
                    value={filter}
                    onChange={(e) => performSearch(e.target.value)}
                />
                <button 
                    className="image-picker-close-button"
                    onClick={() => onChoose(null)}>
                    Close
                </button>
            </div>
            {renderSearchOutput()}
        </div>
    );
};

export default ImagePickerComponent;
