import React, { createContext, useContext, useState, useEffect } from 'react';

const RemoteTabContext = createContext(null);

export const useRemoteTab = () => useContext(RemoteTabContext);

export const RemoteTabProvider = ({ children }) => {
    const [remoteTab, setRemoteTab] = useState(null);

    const openRemoteTab = (url) => {
        if (!remoteTab || remoteTab.closed) {
            const newTab = window.open(url, '_blank');
            setRemoteTab(newTab); // Update the state
            return newTab;
        } else {
            remoteTab.focus();
            return remoteTab;
        }
    };

    const sendMessageToRemoteTab = (message) => {
        if (!remoteTab || remoteTab.closed) {
            const newTab = openRemoteTab('http://localhost:3001/api/display');
            newTab.onload = () => newTab.postMessage(message, 'http://localhost:3001'); // Specify the target origin
            return;
        }
        // console.log("message", message);
        remoteTab.postMessage(message, 'http://localhost:3001'); // Specify the target origin
    };

    // Ensure that when the provider is unmounted, we close the remote tab
    useEffect(() => {
        return () => {
            if (remoteTab && !remoteTab.closed) {
                remoteTab.close();
            }
        };
    }, [remoteTab]);

    return (
        <RemoteTabContext.Provider value={{ openRemoteTab, sendMessageToRemoteTab, remoteTab }}>
            {children}
        </RemoteTabContext.Provider>
    );
};
