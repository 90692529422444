// Plain text paste of stat block from DNDBeyond.com/monsters/ entry. 
// Copy only the portion from within the parchment texture area
import {statblock as statblockDef} from "../PropertyDefs";
import { propertyDefs } from "../PropertyDefs";
import { generateUniqueID } from "../UniqueID";

export default function ParseDNDBeyond(str,monster,db)
{
	if (str.includes("Armor Class") == false)
	{
		// Nothing to parse if there's no mention of Armor Class (the first stat in the list after name and description)
		return null;
	}

	// Find the DB entry
	if (!monster) 
	{ 
		console.error("Can't find monster ",monster); 
		return;
	}

	const vars = str.split('\n');
	// First line we care about is the name
	if (vars[0] == "Beyond 20")
	{
		// If you're using Beyond20 plugin, it injects one extra button at the top
		vars.splice(0,1);
	}
	// First two lines should be name and type
	monster.name = vars[0];
	monster.desc = vars[1];
	const unmatchedData = [];

	// console.log(str,vars)

	// Import blank default statblock to fill out
	monster.statblock = JSON.parse(JSON.stringify(propertyDefs.defaultStatblock));

	// Proficiency bonus is used throughout, so get it first
	for (var i=2;i<vars.length;i++)
	{
		const line = vars[i];
		if (line.startsWith("Proficiency Bonus"))
		{
			const strval = line.replace("Proficiency Bonus ","");
			const justnumber = strval.split(' ')[0]
			const intval = Number(justnumber);
			monster.statblock.prof = intval;
			break;
		}
		else
		if (line.startsWith("Challenge")) {
			const strval = line.replace("Challenge ", "");
			var justnumber = strval.split(' ')[0]
			if (justnumber.includes('/')) {
				const numbs = justnumber.split('/');
				justnumber = numbs[0] / numbs[1];
			}
			const intval = Number(justnumber);
			monster.statblock.cr = intval;
		}
	}

	function calculateProficiencyBonus(cr) {
		if (cr <= 0) return 2; // Assuming CR cannot be negative and CR 0 should have a bonus of +2
		return Math.floor((cr - 1) / 4) + 2;
	}

	if (!monster.statblock.prof || monster.statblock.prof === 0) {
		// Ensuring CR is non-negative
		if (monster.statblock.cr > 0) {
			monster.statblock.prof = calculateProficiencyBonus(monster.statblock.cr);
			console.log(`Assume Proficiency bonus based on CR ${monster.statblock.cr}: ${monster.statblock.prof}`);
		}
	}

	const rollDefs = [];

	const abilityScoreRegex = /\(([^)]+)\)/;
	for (var i=2;i<vars.length;i++)
	{
		const line = vars[i];

		if (line.startsWith("Proficiency Bonus"))
		{
			// Already found it above, so don't include it in the leftovers at the end
		}
		else
		if (line.startsWith("Challenge"))
		{
			// Already found it above, so don't include it in the leftovers at the end
		}
		else
		if (line.startsWith("Armor Class"))
		{
			const strval = line.replace("Armor Class ","");
			const justnumber = strval.split(' ')[0]
			const intval = Number(justnumber);
			monster.statblock.ac = intval;
		}
		else if (line.startsWith("Hit Points"))
		{
			const strval = line.replace("Hit Points ","").split(' ')[0];
			const intval = Number(strval);
			monster.statblock.hp = intval;
		}
		else if (line.startsWith("Speed"))
		{
			const allspeeds = line.replace("Speed ","").split('ft.');
			const strval = allspeeds[0].trim();
			const intval = Number(strval);
			monster.statblock.speed = intval;
			if (strval.length > 0)
			{
				var spd = ""; for (var s=1;s<allspeeds.length;s++) { if (allspeeds[s] != "") spd += " "+allspeeds[s]; }
				if (spd != "") 
				{ 
					spd = "Speed: "+spd;
					unmatchedData.push(spd)
				}
			}
		}
		else if (line.startsWith("Roll Initiative!"))
		{
			const strval = line.replace("Roll Initiative! ","").split(' ')[0];
			const intval = Number(strval);
			monster.statblock.init = intval;
		}
		else if (line=="STR") { monster.statblock.str = Number(vars[i+1].match(abilityScoreRegex)[1]); i++; }
		else if (line=="DEX") { monster.statblock.dex = Number(vars[i+1].match(abilityScoreRegex)[1]); i++; }
		else if (line=="CON") { monster.statblock.con = Number(vars[i+1].match(abilityScoreRegex)[1]); i++; }
		else if (line=="INT") { monster.statblock.int = Number(vars[i+1].match(abilityScoreRegex)[1]); i++; }
		else if (line=="WIS") { monster.statblock.wis = Number(vars[i+1].match(abilityScoreRegex)[1]); i++; }
		else if (line=="CHA") { monster.statblock.cha = Number(vars[i+1].match(abilityScoreRegex)[1]); i++; }
		else if (line.startsWith("Saving Throws"))
		{
			const vals = line.replace("Saving Throws ","").split(',');
			for (var v=0;v<vals.length;v++)
			{
				const statPlusMod = vals[v].trim();
				const split = statPlusMod.split(" ");
				const skill = split[0].toLowerCase();
				const mod = Number(split[1]);
				const matchingAbility = monster.statblock[skill];
				const remainder = mod - matchingAbility;
				// Remainder after subtracting base skillMod should be exactly 1*prof or 2*prof
				const proficient = monster.statblock.prof * 1;
				const expertise = monster.statblock.prof * 2;
				const savename = "save"+skill;
				if (remainder == proficient) { monster.statblock[savename] = 1;}
				else if (remainder == expertise) { monster.statblock[savename] = 2;}
				else { console.warn("Can't identify how "+skill+" scales to "+mod+" with a proficiency bonus of "+monster.statblock.prof); }
				// console.log(skill,"bonus",matchingAbility,mod,"=",savename,monster.statblock[savename])
			}
		}
		else if (line.startsWith("Skills"))
		{
			const vals = line.replace("Skills ","").split(',');
			for (var v=0;v<vals.length;v++)
			{
				const statPlusMod = vals[v].trim();
				// The outlier Sleight of Hand, includes spaces, so we can't split at a space between "Skill +n" 
				const split = statPlusMod.includes("+") ? statPlusMod.split("+") : statPlusMod.split(" ");
				// Clear spaces in sleight of hand
				let ability = split[0].replace(/\s/g, '').toLowerCase().trim();
				const modAsString = split[1].replace("+", "");
				const mod = Number(modAsString);
				// Find the skill to roll against
				const abilityDef = statblockDef.find(o=>o.id===ability);
				if (!abilityDef) 
				{ 
					console.log(statblockDef)
					console.error("Can't find statblock skill def for",ability); 
					return null;
				}
				const matchingAbility = monster.statblock[abilityDef.ability];
				const remainder = mod - matchingAbility;
				// Remainder after subtracting base skillMod should be exactly 1*prof or 2*prof
				const proficient = monster.statblock.prof * 1;
				const expertise = monster.statblock.prof * 2;
				if (remainder == proficient) { monster.statblock[ability] = 1;}
				else if (remainder == expertise) { monster.statblock[ability] = 2;}
				else { console.warn("Can't identify how "+ability+" scales to "+mod+" with a proficiency bonus of "
					+ monster.statblock.prof + "\n" + statPlusMod + "\n" + split); }
				// console.log(ability,"bonus",matchingAbility,mod,"=",ability,monster.statblock[ability])
			}
		}
		else if (
				line.includes("Melee Weapon Attack") || 
				line.includes("Ranged Weapon Attack") || 
				line.includes("Melee Spell Attack") || 
				line.includes("Ranged Spell Attack")
				)
		{
			// Auto-add attack roll
			// console.log(line)

			const attackName = line.split('. ')[0].trim();
			var theRest = line.replace(attackName+". ","");
			const attackType = theRest.split(': ')[0].trim();
			theRest = theRest.replace(attackType+": ","")
			const hitAndDamage = theRest.split('to hit')
			const toHit = hitAndDamage[0].trim();
			theRest = hitAndDamage[1].trim();
			const toDamage = theRest.split('Hit: ');
			theRest = theRest.replace(toDamage[0],"").trim();

			var dam = toDamage[1].trim();
			var damAndEffect = dam.split('damage');
			var damRoll = damAndEffect[0].match(abilityScoreRegex)[1].replace(" ","");
			var rollsplit = damRoll.split('d');
			var dieCount = rollsplit[0];
			var dieType = rollsplit[1];
			var diemod = 0;
			if (dieType.includes("+"))
			{
				var withMod = dieType.split("+");
				dieType = withMod[0]
				diemod = withMod[1]
			}
			else if (dieType.includes("-"))
			{
				var withMod = dieType.split("-");
				dieType = withMod[0]
				diemod = withMod[1]
			}
			var damType = damAndEffect[0].split(')')[1].trim();
			var damExtra = damAndEffect.slice(1).join('damage');
			if (damExtra == ".") { damExtra = ""; }

			// Strip out all the leftover text that didn't make it into the base details, including as "desc"
			var leftover = line;
			leftover = leftover.replace(attackName+". ","");
			leftover = leftover.replace(attackType+": ","");
			leftover = leftover.replace(toHit+" to hit","");
			leftover = leftover.replace("Hit: "+damAndEffect[0]+"damage","");
			leftover = leftover.replace(damExtra,"");

			if (leftover.startsWith(", ")) { leftover = leftover.replace(", ",""); }
			if (damExtra.startsWith(", ")) { damExtra = damExtra.replace(", ",""); }
			if (damExtra.startsWith(". ")) { damExtra = damExtra.replace(". ",""); }
			
			leftover += "<br>"+damExtra;

			leftover = leftover.replace(". .",". ");
			leftover = leftover.replace(" , ","")

			// console.log(attackName,"\n[",attackType,"]\n",toHit,"\n[",dieCount+"d"+dieType+""+diemod+" ]\n",damType,"]\n[",leftover)

			const rollDef = 
			{
				"id": generateUniqueID(),
				"dice": [
					{
						"id": generateUniqueID(),
						"rollType": "attack",
						"count": 1,
						"die": 20,
						"mod": Number(toHit)
					},
					{
						"id": generateUniqueID(),
						"rollType": "damage",
						"count": Number(dieCount),
						"die": Number(dieType),
						"mod": Number(diemod),
						"resultType": damType
					}
				],
				"name": monster.name+" "+attackName,
				"desc": leftover.replace("<br>",""),
				"shortname": attackName,
				"monsters": [
					monster.id
				]
			}

			rollDefs.push(rollDef);
		}
		else if (line.includes("Vulnerabilities") || line.includes("Immunities") || line.includes("Resistances"))
		{
			// TODO: Slot for these if it turns out they're useful. 
			// Until then, just add it to special so it's always visible in Encounter
			if (monster.statblock.special != "") { monster.statblock.special += "  || ";}
			monster.statblock.special += " "+line;
		}
		// else if (line.startsWith("Senses") || line.startsWith("Languages"))
		// {
		// 	// TODO: Slot for these if it turns out they're useful. Until then, leave it in the leftover note
		// 	if (monster.statblock.special != "") { monster.statblock.special += "  || ";}
		// 	monster.statblock.special += " "+line;
		// }
		else
		{
			// Throw all of these into a note
			if (line != "")
			{
				unmatchedData.push(line);
			}
		}
	}

	if (monster.statblock.init === 0)
	{
		// tetra-cube doesn't include a "Roll Initiative" field, so pull the Dex directly
		monster.statblock.init = monster.statblock.dex;
	}

	var note = ""; for (var i=0;i<unmatchedData.length;i++) { note += unmatchedData[i]+"\n";}
	const pathObj = {path:["monsters",monster.id]};

	db.addRollsAndNotesToMonster(pathObj,rollDefs,note);

	// TODO: Add to monster's notes

	return monster;
}