import './ProficiencyDropdown.css';
import React, { useEffect, useState, useRef } from 'react';
import { useDatabase } from '../../DatabaseProvider';

const ProficiencyDropdownComponent = ({ pathObj, property, propertyDef, label, onUpdateValue, className }) => {
    
	const db = useDatabase();
    const [selectedValue, setSelectedValue] = useState(''); // State to hold the selected value

    function changeProficiency(value) {
        const newValue = Number.parseFloat(value);
        if (isNaN(newValue))
        {
            console.error("Can't parse proficiency",value);
        }
        else
        {
            onUpdateValue(pathObj, newValue);
            setSelectedValue(newValue); // Update the state when the value changes
        }
    }

	// Special font amplification
	const emphasis = useRef("proficiency0");

    useEffect(() => {
        const entryData = db.getPropertyByPath(pathObj.path);
        if (entryData[property] != null) {
            setSelectedValue(entryData[property]); // Set the initial value
        } else {
            setSelectedValue(0); // Default value if not found
        }
			
		if (entryData[property] === 1)
		{
			emphasis.current = "proficiency1";
		}
		if (entryData[property] === 2)
		{
			emphasis.current = "proficiency2";
		}
		
    }, [pathObj, db.data, property]);

    const proficiencyDropdown = (
        <select id={pathObj.propertyID} className={"proficiency-dropdown"} value={selectedValue} onChange={(e) => changeProficiency(e.target.value)}>
            <option value={0}>◦</option>
            <option value={0.5}>½</option>
            <option value={1}>⦾</option>
            <option value={2}>⦿</option>
        </select>
    );

	const emphasisClassName = 'proficiency-row '+emphasis.current+' '+className;

    return (
        <div className={emphasisClassName}>
            {label && <label htmlFor={pathObj.propertyID}>{label}</label>}
            {proficiencyDropdown}
        </div>
    );
};

export default ProficiencyDropdownComponent;
