export function GetLabelFromPropDef(propDef,db)
{
	let label = propDef.name;

	let propType = propDef.id || propDef.type;
	// Use the name label override from the db if provided
	const renameProp = db.data.labels.find(o => o.category === propType);
	const catID = renameProp?.name;
	// console.log("propType", propType, "\nrenameProp", renameProp, "\ncatID",catID,"\nlabels", db.data.labels)
	return catID || label;
}