import AddRollButtonComponent from './AddRollButton.jsx';
import { useDatabase } from '../../DatabaseProvider.jsx';
import { propertyDefs } from '../../PropertyDefs.js';
import { renderRollButtons } from './renderRollButtons.jsx';
import { buildRollButtons } from './buildRollButtons.jsx';
import { useRollContext } from '../../contexts/Roll/RollContext.jsx';

const RenderRollsByTypeComponent = () => {
	
	const { pathObj, showRolls, autoExpand, propDef } = useRollContext();

	const db = useDatabase();

	const rollCategoryArrays = propertyDefs.rollActions.reduce((acc, action) => {
		// Initialize each category based on rollActions order
		acc[action.id] = [];
		return acc;
	}, {});

	buildRollButtons(rollCategoryArrays);

	return (
		<div className="full-column">
			<div className='roll-array-frame'>
				{(showRolls || autoExpand) && renderRollButtons(rollCategoryArrays)}
			</div>
			{(showRolls || autoExpand) && db.editMode && <AddRollButtonComponent pathObj={pathObj} propDef={propDef}/>}
		</div>
		
	)
}

export default RenderRollsByTypeComponent;