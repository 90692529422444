import React, { useRef, useState, useEffect } from 'react';
import { categoryList, propertyDefs } from '../../PropertyDefs.js';
import './Sidebar.css';
import SidebarFilterComponent from './SidebarFilter.jsx';
import { useDatabase } from '../../DatabaseProvider.jsx';
import EntryListComponent from './EntryList.jsx';
import CategoryListComponent from './CategoryList.jsx';

// The Sidebar component manages the display and interaction logic for a navigational sidebar.
function SidebarComponent() 
{
	const db = useDatabase();
	const [orderInfo, setOrderInfo] = useState(null);
	const [filterString, setFilterString] = useState('');

	// State to manage the entries that are valid based on multiple filter criteria
	const [validEntries, setValidEntries] = useState([]);

	useEffect(() => {
		// Update orderInfo state when currentCat changes
		if (db.currentEntry && db.currentEntry.categoryID) {
			setOrderInfo(db.data.listManagers[db.currentEntry.categoryID]);
		}
	}, [db.currentEntry, db.data.listManagers]);

	function toggleChildren(listEntryId) {
		// Create a new updated version of orderInfo
		const updatedOrderInfo = orderInfo.map(item => {
			if (item.id === listEntryId) {
				// Toggle the hideChildren property
				return { ...item, hideChildren: !item.hideChildren };
			}
			return item;
		});
	
		// Update the orderInfo state with the new array
		setOrderInfo(updatedOrderInfo);
	}

	function setEntry(catid, entryid) {
		// deselecting a category and entry upon second click
		if (db.currentEntry && db.currentEntry.categoryID === catid && !entryid) {
			db.setCurrentEntry({ "categoryID": undefined, "entryID": undefined });
			return;
		}

		if (entryid && db.currentEntry.entryID === entryid) {
			db.setCurrentEntry({ "categoryID": catid, "entryID": undefined });
			return;
		}

		const curEnt = { "categoryID": catid, "entryID": entryid };
		db.setCurrentEntry(curEnt);
	}

	// Accessing the currently selected category's data
	const selectedCategory = db.data[db.currentEntry.categoryID];

	let categoryListDef
	let categoryTypeDefName;
	let categoryTypeDef;

	if (db.currentEntry.categoryID)
	{
		// Upon selecting a category, grab the matching propertyDef 
		// that will eventually inform the layout of the entry render.
		// But for the sidebar, there might be properties that allow filtering sidebar buttons
		categoryListDef = categoryList.find(o=>o.id===db.currentEntry.categoryID);
		categoryTypeDefName = categoryListDef.type;
		categoryTypeDef = propertyDefs[categoryTypeDefName]
	}

	return (
		<>
			<div className="category-block">
				<CategoryListComponent 
					categoryList={categoryList} 
					currentCategoryID={db.currentEntry.categoryID}
					setEntry={setEntry}
				/>
			</div>
			{selectedCategory && (
				<SidebarFilterComponent 
					currentCategoryID={db.currentEntry.categoryID}
					setFilterString={setFilterString}
					selectedCategory={selectedCategory}
					allEntries={selectedCategory} 
					setFilteredEntries={setValidEntries}
					categoryPropertyDef={categoryTypeDef}
					filteredEntries={validEntries}
					
				/>
			)}
			{selectedCategory && (
				<EntryListComponent 
					setOrderInfo={setOrderInfo}
					orderInfo={orderInfo} 
					currentEnt={db.currentEntry} 
					validEntries={validEntries}
					toggleChildren={toggleChildren}
					setEntry={setEntry}
					currentCat={db.currentEntry.categoryID}
					filterString={filterString}
				/>
			)}
		</>
	);
}

export default SidebarComponent;
