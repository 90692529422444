import './History.css';
import React, { useEffect, useState } from 'react';
import { useDatabase } from '../../DatabaseProvider';
import ButtonNav from '../Buttons/ButtonNav';

const HistoryComponent = () => {
	const db = useDatabase();
	const [history, setHistory] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(-1);

	useEffect(() => {
		// Unlike selecting entries from the sidebar or jumpLinks, setting entry from history does not loop back into the history
		// Leave a note for our future selves
		if (currentIndex >= 0)
		{
			const entry = JSON.parse(JSON.stringify(history[currentIndex]));
			entry.fromHistory = true;
			db.setCurrentEntry(entry);
		}
	},[currentIndex]);


	useEffect(() => {
		// Update when db.currentEntry changes
		if (db.currentEntry && (history.length === 0 || db.currentEntry !== history[currentIndex])) 
		{
			if (!db.currentEntry.categoryID || !db.currentEntry.entryID)
			{
				// console.log("No a full entry choice",db.currentEntry);
				return;
			}
			if (!db.currentEntry.fromHistory)
			{
				const newHistory = history.slice(0, currentIndex + 1); // Reset history from current index
				newHistory.push(db.currentEntry); // Add new entry
				setHistory(newHistory);
				setCurrentIndex(newHistory.length - 1);
			}
		}
	}, [db.currentEntry]); // Only depend on db.currentEntry

	const navigate = (dir) => {
		let newIndex = currentIndex + dir;
		if (newIndex >= 0 && newIndex < history.length) {
			setCurrentIndex(newIndex);
		}
	};

	return (
		<div className='history-button-row'>
			<ButtonNav disabled={currentIndex <= 0} onClick={() => navigate(-1)} label={"<"} />
			<ButtonNav disabled={currentIndex >= history.length - 1} onClick={() => navigate(1)} label={">"} />
		</div>
	);
};

export default HistoryComponent;
