import './Entry.css';
import { propertyDefs } from '../../PropertyDefs.js';
import EditTextComponent from '../../components/EditText/EditText.jsx'
import { useDatabase } from '../../DatabaseProvider';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown.jsx';
import StatBlockComponent from '../../components/StatBlock/StatBlock.jsx';
import RollArray from '../DiceRenderer/RollArray.jsx';
import { generateDropdownOptions } from './generateDropdownOptions.js';
import EditDiceComponent from '../EditDice/EditDice.jsx';
import RollActionComponent from '../RollAction/RollAction.jsx';
import { GetDateAgo } from '../../util/DaysAgo.js';
import { GetLabelFromPropDef } from '../../util/getLabel.js';

// Each unique "type" of property (as identified in properyDefs[categoryDef.type]) has its own editor
function EditorFactory({propDef,index,currentEntryPath})
{
	const selectedCategoryID = currentEntryPath.categoryID;
    const selectedEntryID = currentEntryPath.entryID;

	const db = useDatabase();
	const selectedEntry = db.data[selectedCategoryID][selectedEntryID];

	// Find the value of the id of the propDef within the selectedEntry
	let value = selectedEntry[propDef.id];
	// If there's no property, put up a blank editor so it can be filled in
			
	const typeOfProperty = propDef.type;
	const key = propDef.type+"-"+index;
	let label = GetLabelFromPropDef(propDef,db);

	// Build a path out of the elements that got us up to this place. Updating the database will traverse the db using this path
	const path = 
	[
		currentEntryPath.categoryID,
		currentEntryPath.entryID,
	];


	// Each individual editor gets a little map to lead it back to the piece of data it's manipulating
	const pathObj = {
		"path":path,
		"propertyID":propDef.id
	}

	// Each editor component sends back the path to its location in the database and the new value
	function updateValue(pathObj,value)
	{
		const finalPath = [...pathObj.path, pathObj.propertyID];
		db.updateDB(finalPath, value);
	}

	// Special layout pass: Supplemental Header Info (usually one word each) all goes into one line to save vertical space

	switch (typeOfProperty)
	{
		case "text":
			return <EditTextComponent key={key} onUpdateValue={updateValue} pathObj={pathObj} property={propDef.id} propertyDef={propDef} label={label}/>
		
		case "tribes":
		case "locations":
		case "guilds":
		case "cities":
		case "people":
		case "monsters":
			// These are built from the current state of the referenced category in the database
			const categoryOptions = generateDropdownOptions({categoryID:typeOfProperty})
			const jumpTo = { categoryID: typeOfProperty, entryID: value };
			return <SearchableDropdown 
					key={key} 
					pathObj={pathObj}
					label={label}
					initialValue={value}
					options={categoryOptions}
					onUpdateValue={updateValue}
					prop={typeOfProperty}
					propDef={propDef}
					jumpTo={jumpTo}
				/>
		case "genders":
		case "eventTypes":
		case "structures":
		case "guildCategories":
			// These are hard-coded into the propertyDef
			const defOptions = generateDropdownOptions({propertyDefArray:propertyDefs[typeOfProperty]});
			return <SearchableDropdown 
					key={key} 
					pathObj={pathObj}
					label={label}
					initialValue={value}
					options={defOptions}
					onUpdateValue={updateValue}
					prop={typeOfProperty} 
					propDef={propDef}
				/>
		case "dice":
			return <EditDiceComponent pathObj={pathObj} currentEntryPath={currentEntryPath}/>
		case "rolls":
			// Complex dice definition (attacks, actions, reactions) - clickable button
			const arrayPath = [...pathObj.path];
			arrayPath.push(pathObj.propertyID)
			const rollArray = db.getPropertyByPath(arrayPath);
			const character = db.data[selectedCategoryID][selectedEntryID]
			
			return (
				<div className='full-column'>
					<RollArray key={key} rollArray={rollArray} character={character} pathObj={pathObj} propDef={propDef}/>
				</div>
			)
		case "entries":
			// "entries" is an array of notes. 
			// They get their own separate editor for each note in the array
			return null;
		case "timelineTypes":
			const timelineOptions = generateDropdownOptions({ propertyDefArray: propertyDefs[typeOfProperty] });
			return <SearchableDropdown
				key={key}
				pathObj={pathObj}
				label={label}
				initialValue={value}
				options={timelineOptions}
				onUpdateValue={updateValue}
				prop={typeOfProperty}
				propDef={propDef}
			/>
		case "daysago":
			let currentDay = db.data.vars.currentDay.value
			let ago = "("+GetDateAgo(currentDay, value)+")";
			return (
				<>
					<EditTextComponent
						key={key}
						onUpdateValue={updateValue}
						pathObj={pathObj}
						property={propDef.id}
						propertyDef={propDef}
						label={label}
					/>
					<div className='daysAgo'>{ago}</div>
				</>
				)
		case "statblock":
			// Very specific layout for this huge chunk of data
			return <StatBlockComponent key={key} onUpdateValue={updateValue} pathObj={pathObj}/>
		case "rollActions":
			return <RollActionComponent key={key} className={'roll-set-action-button'} value={value} onUpdateValue={updateValue} pathObj={pathObj}/>
		default:
			// TODO: Every propDef.type should have its own handler
			// readonly, text, image, links, entries, structures, guilds, etc
			console.log("No Entry handler for property type "+typeOfProperty);
			break;
	}
	// HACK! If anything makes it down this far, its custom editor hasn't been made yet
	const friendlyName = propDef.name || propDef.id;
	return (
		<div key={key}>{friendlyName}:{value} ({typeOfProperty})</div>
	)
}
export default EditorFactory;