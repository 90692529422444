import './Tooltip.css';
import React, { useState } from 'react';
import { useDatabase } from '../DatabaseProvider';

const Tooltip = ({ containerClassName, text, children }) => {

	const db = useDatabase();

	const [tooltipStyle, setTooltipStyle] = useState({});
	const [showTooltip, setShowTooltip] = useState(false);

	function setShowTooltipOnDesktopOnly(show)
	{
		if (db.isPortrait) { return; }
		setShowTooltip(show)
	}

	const handleMouseMove = (e) => {
		setTooltipStyle({
			position: 'fixed',
			left: `${e.clientX + 10}px`, // 10px to the right of the cursor
			top: `${e.clientY + 10}px`, // 10px below the cursor
		});
	};

	return (
		<div 
			onMouseMove={handleMouseMove}
			className={containerClassName}
			onMouseEnter={() => setShowTooltipOnDesktopOnly(true)}
			onMouseLeave={() => setShowTooltipOnDesktopOnly(false)}
		>
		{children}
		{(showTooltip && text) &&
			<div className="tooltip" style={tooltipStyle}>
				{text}
			</div>
		}
		</div>
	);
};

export default Tooltip;