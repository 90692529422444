import React, { useState } from 'react';
import './DiceRenderer.css'
import { propertyDefs } from '../../PropertyDefs';
import Tooltip from '../Tooltip';

// Pass in the result of a DiceRoller

const DiceRendererComponent = ({ rollResult }) => {

	function rollSummary() {

		// Roller name and roll
		const rollerName = rollResult.rollerName;
		const rollName = rollResult.shortname || rollResult.name;
		// Get the friendly name (if it's an identified action)
		const rollAction = rollResult.action ? 
    		(propertyDefs.rollActions.find(o => o.id === rollResult.action)?.name || rollResult.action) : 
    		rollResult.action;
		let date = new Date(rollResult.timestamp);
		let timestamp = date.toLocaleTimeString([], {timeStyle: 'short'});

		const rollID = (key) => { 
			return (
				<div key={key+"id"} className='roll-frame-rollertime'>
					<div className='simple-column'>
						<div className='r-rollertime'>{rollerName}</div>
						<div className='r-rollertime'>{timestamp}</div>
					</div>
					<div className='simple-column'>
						<div className='r-rollername'>{rollName}</div>
						{rollAction && <div className='r-rollertime'>{rollAction}</div>}
					</div>
				</div>
		)}

		const oneAttack = (att,index) => {
			let cls = "r-rolltotals";
			if (att.critSuccess) { cls += " r-crit-success"; }
			if (att.critFail) { cls += " r-crit-fail"; }
			return (
				<div key={index} className={cls} >{att.total}</div>
			) 
		}

		const bothAttacks = (attarr,parentKey) => {
			return attarr.map((att,index) => oneAttack(att,parentKey + "-attack-" + index))
		}

		const rollAttack = (key) => {
			const attackRolls = rollResult.attack;
			if (!attackRolls) { return null;}

			let rollD = "1d20";
			const mod = attackRolls[0].mod;
			if (mod)
			{
				const modDir = mod > 0 ? "+":"";
				const m = (mod != 0 ? modDir+""+mod : "") || "";
				if (m !== undefined) { rollD += m };
			}
			
			return (
				<div key={key+"att"} className='roll-frame-rollattack'>
					<div className='simple-column'>
						<div className='r-rollaction attack'>ATT</div>
						<div className='r-rollertime'>{rollD}</div>
					</div>
					<div className='r-advrow'>
					{bothAttacks(attackRolls,key)}
					</div>
				</div>
			)
		}
		
		const rollRecharge = (key) => {
			const rechargeRolls = rollResult.recharge;
			if (!rechargeRolls) { return null;}

			let rollD = "1d6";
			const mod = rechargeRolls[0].mod;
			if (mod)
			{
				const modDir = mod > 0 ? "+":"";
				const m = (mod != 0 ? modDir+""+mod : "") || "";
				if (m !== undefined) { rollD += m };
			}
			
			return (
				<div key={key+"recharge"} className='roll-frame-rollattack'>
					<div className='simple-column'>
						<div className='r-rollaction attack'>Recharge</div>
						<div className='r-rollertime'>{rollD}</div>
					</div>
					<div className='r-advrow'>
						{bothAttacks(rechargeRolls,key)}
					</div>
				</div>
			)
		}

		const rollBonus = (key) => {
			const bonusArray = rollResult.bonus;
			if (!bonusArray) { return null;}
			// Separate for each type. 
			// Often one or more damage can be saved against or might apply to immunity/resistance/weakness
			return bonusArray.map((bonus,index) => 
			{
				const total = bonus.total;
				const dices = "["+bonus.dice.join(',')+"]"
				const rollD = bonus.dice.length+"d"+bonus.d;
				const damtype = bonus.resultType;
				return ( 
					<div key={key+"dam"+bonus.index+index} className='roll-frame-rollbonus'>
						<div className='simple-column'>
							<div className='r-rollaction bonus'>{"Bonus"}</div>
							<div className='r-rollertime'>{rollD}</div>
						</div>
						<div className='r-rolltotals'>{total}</div>
						<div className='simple-column'>
							<div className='r-rollertime'>{damtype}</div>
							<div className='r-rollertime'>{dices}</div>
						</div>
					</div>
				)
			});
		}

		// Saves, skills and abilities
		const rollSimple = (key) => {
			const simpleRolls = rollResult.simple;
			if (!simpleRolls) { return null;}

			// Merge multiple entries to show adv/disadv
			let rollD = "1d20";
			const mod = simpleRolls[0].mod;
			if (mod)
			{
				const modDir = mod > 0 ? "+":"";
				const m = (mod != 0 ? modDir+""+mod : "") || "";
				if (m !== undefined) { rollD += m };
			}

			// TODO: Pass name of simple roll (save, skill, ability)
			return (
				<div key={key+"simple"} className='roll-frame-rollattack'>
					<div className='r-rollaction attack'>{""}</div>
					<div className='r-advrow'>
						{bothAttacks(simpleRolls)}
					</div>		
					<Tooltip text={rollResult.underTheHood}>
						<div className='r-rollertime'>{rollD}</div>
					</Tooltip>
				</div>
			)
		}

		const rollDamage = (key) => {
			const damageArray = rollResult.damage;
			if (!damageArray) { return null;}
			// Separate for each type. 
			// Often one or more damage can be saved against or might apply to immunity/resistance/weakness
			return damageArray.map((dam,index) => 
			{
				const total = dam.total;
				const dices = "["+dam.dice.join(',')+"]"
				const rollD = dam.dice.length+"d"+dam.d;
				const damtype = dam.resultType;
				return ( 
					<div key={key+"dam"+dam.index+index} className='roll-frame-rolldamage'>
						<div className='simple-column'>
							<div className='r-rollaction damage'>{"DAM"}</div>
							<div className='r-rollertime'>{rollD}</div>
						</div>
						<div className='r-rolltotals'>{total}</div>
						<div className='simple-column'>
							<div className='r-rollertime'>{damtype}</div>
							<div className='r-rollertime'>{dices}</div>
						</div>
					</div>
				)
			});
		}

		const rollDesc = (key) => {

			if (!rollResult.desc)
			{
				return null;
			}
			
			let desc = JSON.parse(JSON.stringify(rollResult.desc));

			// Desc can contain dynamic text that injects values from the monster who rolled
			if (rollResult.roller.statblock) {
				let spellsavestat = rollResult.roller.statblock.spellsave;
				let pre = "";
				let suf = "";
			
				if (!spellsavestat) {
					// If there isn't a defined spellsave, assume that the highest stat is the spellcasting modifier 
					const highest = Math.max(
						rollResult.roller.statblock.str,
						rollResult.roller.statblock.dex,
						rollResult.roller.statblock.con,
						rollResult.roller.statblock.int,
						rollResult.roller.statblock.wis,
						rollResult.roller.statblock.cha
					);
					spellsavestat = highest + 10;
					// Use color to highlight that we had to use this alternative lookup
					pre = '<span style="color:#ff8833;display:contents;">';
					suf = '</span>';
				}
			
				// Create the replacement string
				const replacement = pre + spellsavestat + suf;
			
				// Use the replacement string in the replace function
				desc = desc.replace("[spellsave]", replacement);
			} else {
				console.log("No roller statblock");
			}
			return (
				<div key={key + "desc"} className='roll-frame-description'
					 dangerouslySetInnerHTML={{ __html: desc }}
				/>
			);
		}

		const key = rollResult.timestamp; 
		return (
			<div key={key} className='roll-frame-row'>
				{rollID(key)}
				{rollSimple(key)}
				{rollAttack(key)}
				{rollDamage(key)}
				{rollBonus(key)}
				{rollRecharge(key)}
				{rollDesc(key)}
			</div>
		)
	}

	return (
		<div className='rollresult-stack'>
			<div>{rollSummary()}</div>
		</div>
	)
}

export default DiceRendererComponent;

