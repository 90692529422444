import './Entry.css';
import React, { useEffect } from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';
import EditNote from '../Note/Note.jsx';
import EncounterComponent from '../Encounter/Encounter.jsx';

function EntryBodyComponent({ categoryDef, currentEntryPath, expandOverride })
{
	const db = useDatabase();
    const selectedCategoryID = currentEntryPath.categoryID;
    const selectedEntryID = currentEntryPath.entryID;
	
	const selectedEntry = db.data[selectedCategoryID][selectedEntryID];

	// Most category entries are a summary header and a long list of notes.
	// One exception is the Encounter category in which case the body of the entry page is a specialized encounter interface.

	if (categoryDef.id==="encounters")
	{
		return <EncounterComponent encounterDef={selectedEntry}/>
	}
	
	// Split entry into header props (stationary at top) and scrolling list of notes (main body of entry)
	const { notes, eventType } = selectedEntry;

	// Notes is an object with named sub-objects for each entry
	// Build a path out of the elements that got us up to this place. Updating the database will traverse the db using this path
	// Indicate that we've stepped one level deeper into the notes
	const path = 
	[
		currentEntryPath.categoryID,
		currentEntryPath.entryID,
		"notes"
	];

	// Each individual editor gets a little map to lead it back to the piece of data it's manipulating
	// Within the EditNote, set the propertyID according to which property is being edited
	const pathObj = {
		"path":path,
		"propertyID":""
	}

	function reorder(instruction) {
		const newNotesObj = {...notes};
		const { src, dest, dir } = instruction;
		const srcIndex = newNotesObj.noteOrder.ids.indexOf(src);
		const destIndex = newNotesObj.noteOrder.ids.indexOf(dest);
	
		if (srcIndex === -1 || destIndex === -1) {
			console.log("Can't find IDs");
			return;
		}
	
		// Remove the src ID from its current position
		newNotesObj.noteOrder.ids.splice(srcIndex, 1);
	
		// Calculate the new position for the src ID
		let newIndex = destIndex + dir;
	
		// Adjust newIndex for special cases
		if (newIndex < 0) {
			newIndex = 0; // Ensure newIndex is not negative
		} else if (dir === 1 && srcIndex < destIndex) {
			newIndex--; // Adjust for removal of src
		}
	
		// Insert the src ID at the new position
		newNotesObj.noteOrder.ids.splice(newIndex, 0, src);
	
		// Update its value in the db
		db.updateDB(path, newNotesObj);
	}
	
	if (notes) 
	{
		// Find the order object
		let noteOrder = notes.noteOrder;
		// Sanity check
		let noteObjects = Object.values(notes);
		// There's always at least one object in the noteObjects (the noteOrder)
		if (!noteOrder)
		{
			const notesOrder = {"ids":[]};
			notes.noteOrder = notesOrder;
			noteOrder = notes.noteOrder; 
		}
		// Brand new object won't have any notes yet
		if (noteObjects && noteOrder && noteOrder.ids)
		{
			if ((noteObjects.length-1) != noteOrder.ids.length)
			{
				console.error("Mismatch between noteOrder and actual note objects",noteOrder,noteObjects)
			}
		}

		function deleteNote(id)
		{
			// Remove the entry from the db, and remove the id from the noteOrder
			const newNotes = {...db.data[currentEntryPath.categoryID][currentEntryPath.entryID]["notes"]};
			const indexInOrder = newNotes.noteOrder.ids.indexOf(id)
			if (indexInOrder == -1)
			{
				console.error("Can't find entry in order");
			}
			newNotes.noteOrder.ids.splice(indexInOrder,1);
			// And elmininate that object
			delete newNotes[id];
			const fullPath = [currentEntryPath.categoryID,currentEntryPath.entryID,"notes"];
			db.updateValueByPath(fullPath,newNotes)
		}

		// Iterate through the noteOrder to draw the notes
		return noteOrder.ids.map((noteID,index)=> {
			const noteKey = `note-${noteID}`;
			if (noteID !== "noteOrder")
			{
				// Grab the contents of that note
				const noteDef = notes[noteID];
				return (
					<div id={noteKey} key={noteKey}>
						<EditNote 
							masterExpand={expandOverride} 
							pathObj={pathObj} 
							noteDef={noteDef} 
							index={index}
							reorderCallback={reorder} 
							deleteCallback={deleteNote}
						/>
					</div>
				);
			}
		})

	}
}
export default EntryBodyComponent;