export default function referredList(db,categoryList,selectedEntryID)
{
	const referred=[];
	// Go through every mention in every note of every entry in the database to see which ones refer to this entry
	for (var c=0;c<categoryList.length;c++)
	{
		const catid = categoryList[c].id;
		const category = db.data[catid];
		if (!category) { continue; }
		Object.values(category).forEach((entry)=> {
			if (entry.notes)
			{
				Object.values(entry.notes).forEach((note)=> {
					if (note.mentions)
					{
						// Mentions are an array, not a named object (so they can be directly sorted)
						for (var m=0;m<note.mentions.length;m++)
						{
							const mention = note.mentions[m];
							if (mention.entryID && mention.entryID === selectedEntryID)
							{
								// That's me!
								const reference = {
									name: note.name, 
									link: {
											categoryID:catid,
											entryID:entry.id,
											noteID:note.id
										}
									};
								referred.push(reference);
							}
						}
					}		
				});
			}
		});
	}
	return referred;
}