import '../DiceRenderer/DiceRenderer.css'
import React, { useEffect } from 'react';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown.jsx';
import { generateDropdownOptions } from '../Entry/generateDropdownOptions.js';
import { propertyDefs } from '../../PropertyDefs.js';

function RollActionComponent({pathObj,value,onUpdateValue})
{
	const actionOptions = generateDropdownOptions({propertyDefArray:propertyDefs.rollActions})
	const propDef = {placeholder:"action type"}

	return (
		<SearchableDropdown  
			label={"Action Type"}
			className={"roll-set-action-button"}
			initialValue={value}
			options={actionOptions}
			onUpdateValue={onUpdateValue}
			propDef={propDef}
			pathObj={pathObj}
		/>
	)

}
export default RollActionComponent;

