import { db } from './idbUtil';

export async function getLocalStorage(key) {
	const database = await db;
	const result = await database.get('localStorage', key);
	return result;
}

export async function setLocalStorage(key, value) {
	const database = await db;
	return await database.put('localStorage', value, key);
}

export async function addIDToList(storageKey,id,callback) {
	let list = await getLocalStorage(storageKey) || [];
	if (!list.includes(id)) {
		list.push(id);
		await setLocalStorage(storageKey, list);
		if (callback) { callback(true); }
	}
	else
	{
		// No need to change. Immediately call back
		if (callback) { callback(true); }
	}
}

export async function removeIDFromList(storageKey,id,callback) {
	let list = await getLocalStorage(storageKey) || [];
	const index = list.indexOf(id);
	if (index > -1) {
		list.splice(index, 1);
		await setLocalStorage(storageKey, list);
		if (callback) { callback(false); }
	}
	else
	{
		// No need to change. Immediately call back
		if (callback) { callback(false); }
	}
}

export async function isIDInList(storageKey,id) {
	const list = await getLocalStorage(storageKey) || [];
	return list.includes(id);
}