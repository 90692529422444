import React, { createContext, useContext, useState } from 'react';

const RollContext = createContext();

export const useRollContext = () => useContext(RollContext);

export const RollProvider = ({ children, value }) => {
    return (
        <RollContext.Provider value={value}>
            {children}
        </RollContext.Provider>
    );
};
