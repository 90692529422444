import React, { createContext, useContext, useState, useEffect } from 'react';

// Create Context
const DeviceTypeContext = createContext();

// Custom Hook for detecting device type
function useDeviceType() {
  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    const checkDeviceType = () => {
      setDeviceType(window.innerWidth < 768 ? 'mobile' : 'desktop');
    };

    window.addEventListener('resize', checkDeviceType);
    checkDeviceType();

    return () => window.removeEventListener('resize', checkDeviceType);
  }, []);

  return deviceType;
}

// Context Provider Component
export const DeviceTypeProvider = ({ children }) => {
  const deviceType = useDeviceType();

  return (
    <DeviceTypeContext.Provider value={deviceType}>
      {children}
    </DeviceTypeContext.Provider>
  );
};

// Hook for using device type context
export const useDeviceTypeContext = () => useContext(DeviceTypeContext);
