import { useRollContext } from '../../contexts/Roll/RollContext.jsx';
import { useDatabase } from '../../DatabaseProvider.jsx';

export const useReorder = () => {
	
	const db = useDatabase();

	const { pathObj, character } = useRollContext();

	const reorder = (dir, rollDef) => {
		
		const currentIndex = character.rolls.findIndex(roll => roll.id === rollDef.id);

		// Check if there's at least one other entry with the same action type
		const sameActionTypeExists = character.rolls.some((roll, index) => getRollAction(roll.id,db) === rollDef.action && index !== currentIndex);
	
		function getRollAction(rollID,db)
		{
			const rollDef = db.data.rolls[rollID];
			return rollDef.action;
		}
	
		if (currentIndex !== -1 && sameActionTypeExists) {
			let newIndex = currentIndex;
	
			// Loop to move the item until it crosses another of the same action type
			while (true) {
				const nextIndex = newIndex + dir;
	
				// Check bounds
				if (nextIndex < 0 || nextIndex >= character.rolls.length) {
					break;
				}
	
				// Check if the next item is of the same action type
				if (getRollAction(character.rolls[nextIndex].id,db) === rollDef.action) {
					// Move the item and break after crossing an item of the same action type
					[character.rolls[newIndex], character.rolls[nextIndex]] = [character.rolls[nextIndex], character.rolls[newIndex]];
					break;
				}
	
				// Move the item
				[character.rolls[newIndex], character.rolls[nextIndex]] = [character.rolls[nextIndex], character.rolls[newIndex]];
				newIndex = nextIndex;
			}
	
			// Update the database with the new order
			const updateRolls = JSON.parse(JSON.stringify(character.rolls));
			db.updateDB(pathObj, updateRolls);
		}
    };

    return reorder;
}