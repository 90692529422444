
	// Button that does an action in-place without altering the scene (like save)
	// Using divs to allow nesting
	const ButtonAction = ({ onClick, className, label }) => {

		// Append any extra classes
		const fullClassName = 'button-default button-action '+(className ? className : '');

		return (
			<div className={fullClassName} onClick={onClick}>
				{label}
			</div>
		)
	};

	export default ButtonAction;