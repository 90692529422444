
	// Button that fundamentally changes the state of the editor (like switching from View to Edit mode)
	
	// Using divs to allow nesting
	
	const ButtonState = ({ onClick, className, label }) => {

		// Append any extra classes
		const fullClassName = 'button-default button-state '+(className ? className : '');
		return (
			<div className={fullClassName} onClick={onClick}>
				{label}
			</div>
		)
	};

	export default ButtonState;