import './DiceRenderer.css'
import React, { useState, useContext } from 'react';
import diceRoller from '../../util/DiceRoller/DiceRoller';
import DiceRendererComponent from './DiceRenderer';
import { RollHistoryContext } from '../../contexts/RollHistory/RollHistoryContext';
import { useDatabase } from '../../DatabaseProvider';
import { propertyDefs } from '../../PropertyDefs';

// Pass in saved rollDef from database. Dice are gathered up and rolled by the DiceRoller

const Roll = ({ rollDef, sendResultToHistory, character, reorder }) => {

	const [diceResults, setDiceResults] = useState(null);
	const [showResults, setShowResults] = useState(true);
	const { addRoll, rollHistory } = useContext(RollHistoryContext);
	const db = useDatabase();

	const toggleResults = () => {
		setShowResults(!showResults)
	}
	const rollAllTheDice = () => {
		// console.log("::::::::::::Setup roll",rollDef)
		// Call the diceRoller function and pass the callback to handle results
		diceRoller(rollDef, null, (results) => {
			// Handle the results of the dice roll, e.g., by setting state
			setDiceResults(results);
			if (sendResultToHistory)
			{
				addRoll(results)
			}
		});
		if (!sendResultToHistory)
		{
			setShowResults(true);
		}
	};

	// console.log(rollDef)
	// Find the className from the rollTypes
	const rollAction = propertyDefs.rollActions.find(o=>o.id===rollDef.action);
	const actionClass = rollAction ? rollAction.class : "";
	const buttonClassName = "roll-dice-button " + actionClass;
	const rollname = rollDef.shortname || rollDef.name;
	
	function jumpToRoll(event)
	{
		const instruction = {categoryID:"rolls",entryID:rollDef.id};
		db.setCurrentEntry(instruction);
	}

	function removeRoll(event)
	{
		const pathObj = {path:["monsters",character.id]}
		db.removeRollFromMonster(pathObj,rollDef.id)
	}

	const wrapperClass = 'roll-name-and-toggle ' + (db.editMode ? ' roll-editor-box' : '');
	return (
		<>
			<div className={wrapperClass}>
				{db.editMode && <button className='arrange-roll-button' onClick={()=> {reorder(-1,rollDef)}}>{'<'}</button>}
				{db.editMode && <button className='jump-to-roll' onClick={jumpToRoll}>{'✎'}</button>}
				<button className={buttonClassName} onClick={rollAllTheDice}>
					{rollname}
				</button>
				{db.editMode && <button className='delete-button' onClick={removeRoll}>{'X'}</button>}
				{db.editMode && <button className='arrange-roll-button' onClick={()=> {reorder(1,rollDef)}}>{'>'}</button>}
				{(!sendResultToHistory && diceResults) &&
					<button className={"result-toggle"} onClick={toggleResults}>
						{showResults ? "v":">"}
					</button>
				}
			</div>
			{!sendResultToHistory && diceResults && showResults && <DiceRendererComponent rollResult={diceResults} />}
		</>
	);
};

export default Roll;
