import './SidebarButton.css';
import React from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';

const SidebarButtonComponent = ({ 
	entry, 
	indentClass, 
	isSelected, 
	toggleChildren, 
	setEntry, 
	currentCat, 
	fullEntryName, 
	arrow, 
	onDragStart,
	onDragEnd,
	onDrop,
	onDragEnter,
	onDragLeave,
	hoverButtonClass,
	prefix
}) => {

	const db = useDatabase();

	const handleDragOver = (e) => {
		// Must ignore in order to allow drop
		e.preventDefault();
	};

	function toggleButton(entry)
	{
		return (
			<>
				{entry.children && (
					<div 
						className={`sidebar-div-toggle ${indentClass}`}
						onClick={() => toggleChildren(entry.id)}
					>
						{arrow}
					</div>
				)}
			</>	
		)
	}

	function mainButton(entry)
	{
		const fullClassName = hoverButtonClass+" "+(`button-sidebar ${isSelected ? 'button-sidebar-entry-selected' : 'button-sidebar-entry'}`);
		return (
			<div
				className={fullClassName}
				onClick={() => setEntry(currentCat, entry.id)}
			>
				{fullEntryName || 'Unnamed Entry'}
			</div>
		)
	}

	function appendPrefix()
	{
		if (!prefix) { return null; }

		return (
			<div className='sidebar-prefix' >{prefix}</div>
		)

	}

	return (
		<div className='space-between-row' key={entry.id}
			draggable={db.editMode}
			onDragStart={(e) => onDragStart(e, entry)}
			onDragEnd={(e) => onDragEnd(e, entry)}
			onDrop={(e) => onDrop(e, entry)}
			onDragOver={handleDragOver}
			onDragEnter={(e) => onDragEnter(e, entry)}
			onDragLeave={(e) => onDragLeave(e, entry)}
		>
			<div key={entry.id} className='sidebar-button-row'>
				{toggleButton(entry)}
				{mainButton(entry)}
				{appendPrefix()}
			</div>
			
		</div>
    );
};

export default SidebarButtonComponent;
