import { propertyDefs } from '../../PropertyDefs';

const DiceRoller = (rollDef, advOrDisadv="flat", returnResult) => 
{
	// TODO: Hook up to global counter to track every die rolled to ensure it's fair over time
	// Save in local storage, occasionally dumping results to DB
	let rawDice = [];
	
	const rollOneDie = (dieDef) =>
	{
		const rollResults = {"total":0,"dice":[],"rollType":dieDef.rollType,"d":dieDef.die};
		for (var i = 0; i < dieDef.count; i++) 
		{
			const roll = Math.floor(Math.random() * dieDef.die) + 1;
			rawDice.push({"d":dieDef.die,"roll":roll});
			rollResults.total += roll;
			rollResults.dice.push(roll);
		}
		if (dieDef.mod)
		{
			rollResults.total += dieDef.mod;
		}
		
		if (dieDef.mod)
		{
			rollResults.mod = dieDef.mod;
		}
		if (dieDef.min != undefined)
		{
			if (rollResults.total < dieDef.min)
			{
				rollResults.total = dieDef.min;
			}
		}
		if (dieDef.resultType)
		{
			const resultDef = propertyDefs.rollResultTypes.find(o=>o.id===dieDef.resultType);
			const resultName = resultDef ? resultDef.name : dieDef.resultType;
			rollResults.resultType = resultName;
		}
	
		return rollResults;
	}
	
	const rollAllDice = () => 
	{
		// Final result concatenates all the roll stuff into a object separate from the roll definition
		// carrying over just the static information and adding the dynamically-rolled values here
		let finalResult = {
			rollerName: rollDef.rollerName,
			roller: rollDef.roller,
			name: rollDef.name,
			desc: rollDef.desc,
			underTheHood: rollDef.underTheHood
		};

		var results = [];

		if (!rollDef.dice)
		{
			// No dice means just show the description (trait roll types are just reminders)
		}
		else
		{
			// Go through all the dice in the entire def
			rollDef.dice.forEach((die,index) => 
			{
				let res = rollOneDie(die);
		
				// If it rolled the max value for the die face
				if (res.dice.includes(die.die))
				{
					res.critSuccess = true;
				}
				// Or a nat 1
				if (res.dice.includes(1))
				{
					res.critFail = true;
				}
				results.push(res);
				// Always roll both if adv is allowed. Reporting results will share first or both according to need
				const rollType = propertyDefs.rollTypes.find(o=>o.id===die.rollType);
				if (rollType && rollType.canRollAdv && advOrDisadv != "flat")
				{
					results.push(rollOneDie(die));
				}
			});
		}
	
		for (var i=0;i<results.length;i++)
		{
			const res = results[i];
			// Make an array for each type of roll (there can by multiple damage rolls included with single attack)
			if (!finalResult[res.rollType]) { finalResult[res.rollType] = []; }
			finalResult[res.rollType].push(res);
		}
	
		if (rollDef.desc) { finalResult.desc = rollDef.desc; }
		finalResult.timestamp = Date.now();
		finalResult.shortname = rollDef.shortname;
		finalResult.action = rollDef.action;

		return finalResult;
	};

	const res = rollAllDice(rollDef, advOrDisadv);

	// Invoke the callback with the results
	returnResult(res);
}
export default DiceRoller;