import useJsonFileList from './filelist.jsx';

const FilePicker = ({ onFileSelect }) => {
	const { files, loading, error } = useJsonFileList();

	if (loading) return <p>Loading files...</p>;
	if (error) return <p>Error loading files!</p>;

	return (
		<div>
			{files.map(file => (
				<button key={file} onClick={() => onFileSelect(file)} style={{ margin: '5px' }}>
					{file}
				</button>
			))}
		</div>
	);
};

export default FilePicker;
