import './MentionManager.css'
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useDatabase } from '../../DatabaseProvider';
import EditTextComponent from '../EditText/EditText';
import { SearchContext } from '../../contexts/SearchContext.jsx';
import ButtonJump from '../Buttons/ButtonJump.jsx';

const MentionEditComponent = ({ mention, pathObj, index }) => 
{
	const db = useDatabase();
	const { openSearch } = useContext(SearchContext);
	const [showMentionEditor, setShowMentionEditor] = useState(false);
	const [linkType, setLinkType] = useState(mention.categoryID != null);

	// onClick callbacks aren't updated when linkType changes
	const linkTypeRef = useRef();
	const mentionRef = useRef();

	useEffect(()=> {
		if (mention)
		{
			mentionRef.current = mention;
			setLinkType(mention.categoryID != null ? "db" : "url");
		}
	}, [mention])

	useEffect(()=> {
		if (linkType)
		{
			linkTypeRef.current = linkType;
		}
	}, [linkType])

	// TODO: ? Convert to named object? But then we'll have to use listManager it for order...
	// This is the final address of this particular member of the mention array down to the array index
	pathObj.propertyID = index;
	// Make a deep copy to isolate to this instance alone
	pathObj = JSON.parse(JSON.stringify(pathObj));

	// Dig out the appropriate entry title or label
	// URLs have custom label assigned.
	let label = mention.name || "unnamed";
	
	var className = 'mention-button-edit'

	// internal links pull their label from the title of the linked entry
	if (linkType === "db")
	{
		const targetEntry = db.getPropertyByPath([mention.categoryID,mention.entryID]);
		if (targetEntry) {
			label = targetEntry.name;			
		}
		else
		{
			label = "No target set";
		}
	}
	else
	{
		className +=' mention-button-edit-url';
	}

	return (
		<div id={"edit-row-"+mention.id} className={'mention-edit-row'}>
			<button className={'mention-button-delete'} onClick={() => { deleteMention(mention); }} >{"X"}</button>
			<button 
				className={className}
				key={mention.id}
				onClick={() => {editMention()}}
				>
					{label}
			</button>
			{showMentionEditor && mentionEditor(mention)}
		</div>
	);

	function editMention()
	{
		// Toggle
		setShowMentionEditor(!showMentionEditor);
	}

	function deleteMention()
	{
		const finalPath = [...pathObj.path];
		finalPath.push(pathObj.propertyID);
		db.updateValueByPath(finalPath,null);
	}

	function updateMention()
	{
		const finalPath = [...pathObj.path];
		finalPath.push(pathObj.propertyID);
		// Delete categoryID or URL when saving opposite
		const updatedMention = {...mentionRef.current};
		if (linkTypeRef.current === "db") { delete updatedMention.url; }
		if (linkTypeRef.current === "url") 
		{ 
			delete updatedMention.categoryID; 
			delete updatedMention.entryID; 
			delete updatedMention.noteID; 
		}
		console.log(linkTypeRef.current,"Updated mention:",updatedMention);
		setShowMentionEditor(false);
		db.updateValueByPath(finalPath,updatedMention);
	}

	function mentionEditor()
	{
		const buttonBaseClass = "mention-type-button ";
		const switchToDB = () => {
			const entryClass = buttonBaseClass + (linkType === "db" ? "" : "mention-not-current-option");
			return (<button className={entryClass} onClick={()=>{setLinkType("db");}}>Entry</button>)
		};
		const switchToURL = () => {
			const entryClass = buttonBaseClass + (linkType === "url" ? "" : "mention-not-current-option");
			return (<button className={entryClass} onClick={()=>{setLinkType("url");}}>URL</button>)
		};

		const switchBlock = (
			<div className='mention-edit-typeswitch'>{switchToDB()}{switchToURL()}</div>
		)

		function findEntry()
		{
			openSearch(pathObj);
		}

		const editor = () => {
			if (linkType === "db")
			{
				// From DB provides a searchable dropdown using global find
				return <ButtonJump onClick={findEntry} label={"Find Entry"}/>
			}
			else
			{
				// Plain text editor. Make a fake propertyDef and send simple instructions
				const propertyDefURL = { placeholder:"URL" }
				const propertyDefName = { placeholder:"Name" }
				return (
					<>
						<EditTextComponent propertyDef={propertyDefURL} simpleCallback={updateURL} simpleValue={mentionRef.current.url || ""}/>
						<EditTextComponent propertyDef={propertyDefName} simpleCallback={updateName} simpleValue={mentionRef.current.name || ""}/>
					</>
				)
				return 
			}
		}

		function updateURL(url)
		{
			mentionRef.current.url = url;
			// console.log(mentionRef.current)
		}
		
		function updateName(name)
		{
			mentionRef.current.name = name;
			// console.log(mentionRef.current)
		}
	
		return (
			<>
				{switchBlock}
				{editor()}
				<button className='mention-edit-ok' onClick={()=>{updateMention()}}>OK</button>
			</>
		)
	}
}
export default MentionEditComponent