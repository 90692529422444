import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for the image cache
const ImageCacheContext = createContext({});

// This provider will wrap your application at a high level
export const ImageProvider = ({ children }) => {
const [cache, setCache] = useState({});

// The function to get an image, either from cache or fetch from the server
const getImage = async (src) => {
	// If the image is in the cache, return it
	if (cache[src]) {
	return cache[src];
	}
	
	// If the image is not in the cache, fetch it from the server
	const response = await fetch(src);
	const imageBlob = await response.blob();
	const imageUrl = URL.createObjectURL(imageBlob);

	// Update the cache with the new image
	setCache((currentCache) => ({ ...currentCache, [src]: imageUrl }));

	return imageUrl;
};

return (
	<ImageCacheContext.Provider value={{ getImage, cache }}>
	{children}
	</ImageCacheContext.Provider>
);
};

// Hook to use the image cache context
export const useImageCache = () => {
	const context = useContext(ImageCacheContext);
	if (context === undefined) {
		throw new Error('useImageCache must be used within an ImageProvider');
	}
	return context;
};

export const useCachedImage = (src) => {
	const [imageUrl, setImageUrl] = useState(null);
	const { getImage } = useImageCache();

	useEffect(() => {
		if (!src) { return; }
		let isMounted = true;
		const fetchImage = async () => {
			const cachedImageUrl = await getImage(src);
			if (isMounted) {
				setImageUrl(cachedImageUrl);
			}
		};

		fetchImage();

		return () => {
			isMounted = false;
		};
	}, [src, getImage]);

	return imageUrl;
};
