// Add / remove categories to ignore in the current session
const prohibitedCategories = ['sanity check', 'db', 'db save', 'blocked', 'db update', 'db set'];

const categoryDefs = [
    {id:"db update","color":"red","trace":true}
]

export function smartLog(...args) {
    let category = null;

    // Check for special keys and remove them from args
    args = args.filter(arg => {
        if (arg && typeof arg === 'object' && arg.cat) {
            category = arg.cat;
            return false;
        }
        return true;
    });

    // Implement filtering logic
    if (!prohibitedCategories.includes(category)) {

        const def = categoryDefs.find(o=>o.id===category);
        let color = "grey";
        let trace = false;
        if (def)
        {
            if (def.color) { color = def.color; }
            if (def.trace) { trace = def.trace; }
        }
        // Prefix the category label to the message
        const label = category ? `%c[${category}]: ` : '';
		const style = category ? "color: grey" : "";
        args = [label, style, ...args];
        console.log(...args); 
        if (trace) 
        {
            console.trace();
        }
    }
}

// Example usage:
// smartLog({ cat: 'debug' }, 'This is a debug message', { someObject: true });
// smartLog({ cat: 'info' }, 'This is an info message', [1, 2, 3]);
