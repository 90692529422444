export function buildHierarchy(entries, parentId = null) {
	entries = Array.isArray(entries) ? entries : [];
	const entriesMap = new Map(entries.map(entry => [entry.id, entry]));

	// Function to get child entries in the order specified by the parent's 'children' array
	function getChildren(parent) {
		return (parent.children || []).map(childId => entriesMap.get(childId)).filter(child => child);
	}

	// Ensure rootOrder exists and set parentID for top-level entries
	if (parentId === null) {
		let rootOrderEntry = entriesMap.get('rootOrder');
		if (!rootOrderEntry) {
			// Find top-level entries and construct rootOrder
			const topLevelEntries = entries.filter(entry => !entry.parentID);
			const topLevelIds = topLevelEntries.map(entry => entry.id);
			rootOrderEntry = { id: 'rootOrder', children: topLevelIds };
			entriesMap.set('rootOrder', rootOrderEntry);
			entries.push(rootOrderEntry);

			// Update parentID for top-level entries
			topLevelEntries.forEach(entry => {
				entry.parentID = 'rootOrder';
			});
		}
		parentId = 'rootOrder';
	}

	const parent = entriesMap.get(parentId);
	return getChildren(parent).map(entry => ({
		...entry,
		children: buildHierarchy(entries, entry.id)
	}));
}

// flattenHierarchy takes a hierarchical structure and flattens it into a single array.
export function flattenHierarchy(hierarchy, depth = 0) {
	return hierarchy.reduce((acc, entry) => {
		// Adding depth for indenting visualization
		entry.depth = depth;
		// Combine current entry with recursive flattening of its children
		return acc.concat({ ...entry }, flattenHierarchy(entry.children, depth + 1));
	}, []);
}
