
	// Button that does an action in-place without altering the scene (like save)
	// Using divs to allow nesting
	const ButtonNav = ({ onClick, className, label, disabled }) => {

		// Append any extra classes
		const disabledClass = disabled ? ' button-nav-disabled':'';
		const fullClassName = 'button-default button-nav '+(className ? className : '')+disabledClass;

		return (
			<div className={fullClassName} onClick={!disabled ? onClick : null}>
				{label}
			</div>
		)
	};

	export default ButtonNav;