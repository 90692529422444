import { propertyDefs } from '../../PropertyDefs.js';

export function renderRollButtons(rollCategories)
{
	return (
		propertyDefs.rollActions.map(action => {
			// Check if the roll category has rolls before rendering
			if (rollCategories[action.id].length > 0) {
				return (
					<div key={action.id} className={action.class}>
						<div className='roll-array-title'>{action.name}</div>
						<div className='roll-array-row'>
							{rollCategories[action.id]}
						</div>
					</div>
				);
			} else {
				return null; // Return null if no rolls are present for this action
			}
		})
	)
}