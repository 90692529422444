import ButtonAdd from '../Buttons/ButtonAdd.jsx';
import { useDatabase } from '../../DatabaseProvider.jsx';
import { generateUniqueID } from '../../UniqueID.js';
import { generateDropdownOptions } from '../Entry/generateDropdownOptions.js';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown.jsx';

const AddRollButtonComponent = ({pathObj,propDef}) => {
	
	const typeOfProperty= propDef?.type;
	const key = propDef?.type+"-";

	const db = useDatabase();

	function addNewRoll() {
		// Add a blank roll def
		const rollEntry = {id:generateUniqueID()};
		db.addRollToMonster(pathObj,rollEntry);
	}

	// Allow adding new rolls from dropdown
	const rollOptions = generateDropdownOptions({categoryID:"rolls"});
	const addFromExisting = () => {
		return (
			<SearchableDropdown 
				key={key+"newroll"} 
				pathObj={pathObj}
				labelOverride={"Add Existing Roll"}
				initialValue={""}
				options={rollOptions}
				onUpdateValue={(pathObj, value) => { addRoll(value) }}
				prop={typeOfProperty} 
				propDef={propDef}
			/>
		) 
	}

	function addRoll(rollID)
	{
		db.addExistingRollToMonster(pathObj,rollID)
	}

	const createNewRoll = () => {
		return (
			<ButtonAdd label={"New Roll"} onClick={()=>addNewRoll()} />
		) 
	}

	return (
		<div className='add-roll-button-row'>
			{createNewRoll()}
			{addFromExisting()}
		</div>
	)
}

export default AddRollButtonComponent
