import { useDatabase } from '../../DatabaseProvider';
import { GetLabelFromPropDef } from '../../util/getLabel';
import Tooltip from '../Tooltip';
import './Sidebar.css';

// List of categories in sidebar. Collapses when one is selected

const CategoryListComponent = ({ categoryList, currentCategoryID, setEntry }) => {
	
	return categoryList.map((cat, index) => {
		const isCurrent = cat.id === currentCategoryID;
		const shouldShowButton = !currentCategoryID || isCurrent;
		const growClass = shouldShowButton ? 'button-sidebar-full' : 'button-sidebar-empty';
		const highlightClass = cat.id === currentCategoryID ? 'button-sidebar button-sidebar-selected' : 'button-sidebar';
		const className = `${growClass} ${highlightClass}`;
		const tooltip = cat.tooltip;

		const db = useDatabase();

		const buttonLabel = () =>
		{
			const label = GetLabelFromPropDef(cat,db);
			const arrow = isCurrent ? "⇩" : "⇨";
			return (
				<>
					<div style={{backgroundColor:"inherit"}}>{arrow}</div>
					<div style={{backgroundColor:"inherit"}}>{label}</div>
					<div>{""}</div>	
				</>
			)
		}

		return (
			<Tooltip 
				key={cat.id + index +" tooltip"}
				containerClassName={'full-row'} 
				text={tooltip}
			>
				<div className={className+' button-row'} onClick={() => setEntry(cat.id, null)}>
					{shouldShowButton && buttonLabel()}
				</div>
			</Tooltip>
		);
	});
};
export default CategoryListComponent;