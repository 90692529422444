import React, { useEffect, useState, createContext } from 'react';
import { categoryList } from '../PropertyDefs.js'; // Adjust import as needed
import { useDatabase } from '../DatabaseProvider.jsx'; // Adjust import as needed

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [isSearchOpen, setSearchOpen] = useState(null);
    const [filter, setFilter] = useState('');
    const [searchResults, setSearchResults] = useState({});
    const db = useDatabase(); // Assuming you have a hook to access your database

	useEffect(()=> {
		// Even when we start with the blank, start rendering it
		performSearch('')
	},[])

    const openSearch = (pathObj) => 
	{
		// If a pathObj is included, clicking a target sends the categoryID,entryID,noteID back to the target path.
		// This is used to add Mentions to Notes
		setSearchOpen({open:true, destPathObj:pathObj}); 
	}
    const closeSearch = () => setSearchOpen(null);

	const performSearch = (newFilter) => {
		if (!db.data) { return; }
		setFilter(newFilter);
		const criteria = newFilter.toLowerCase().split(' ').filter(Boolean);
		const result = {};
		for (const category of categoryList) {
			const entries = db.data[category.id];
	
			for (const key in entries) {
				const entry = entries[key];
	
				const isMatchInText = (text) => {
					if (!text) return false;
					return criteria.every(crit => text.toLowerCase().includes(crit));
				};
				
	
				const notesMatch = Object.values(entry.notes || {}).filter(note => 
					isMatchInText(note.txt)
				);
	
				const entryMatch = isMatchInText(entry.name) || 
								   isMatchInText(entry.desc) ||
								   notesMatch.length > 0;
	
				if (entryMatch) {
					if (!result[category.id]) {
						result[category.id] = [];
					}
					result[category.id].push({
						...entry,
						matchedNotes: notesMatch
					});
				}
			}
		}
	
		setSearchResults(result);
	};
	

    return (
        <SearchContext.Provider value={{ isSearchOpen, openSearch, closeSearch, filter, searchResults, performSearch }}>
            {children}
        </SearchContext.Provider>
    );
};
