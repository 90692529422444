// Panel containing all of a character's custom dice
import './DiceRenderer.css'
import React, { useState, useEffect } from 'react';
import ButtonExpand from '../Buttons/ButtonExpand.jsx';
import { addIDToList, removeIDFromList, isIDInList } from '../../util/localStorageUtils.js';
import RenderRollsByTypeComponent from './RenderRollsByType.jsx';
import { RollProvider } from '../../contexts/Roll/RollContext.jsx';
// Array of roll buttons in character sheet

const RollArray = ({ rollArray, character, sendResultToHistory, autoExpand, pathObj, propDef }) =>
{
	const [showRolls,setShowRolls] = useState(false);
	
	const LOCAL_STORAGE_KEY = 'showRollIDs';

	async function toggleActions() {
		const newShowActions = !showRolls;
		if (newShowActions) {
			await addIDToList(LOCAL_STORAGE_KEY,character.id,setShowRolls);
		} else {
			await removeIDFromList(LOCAL_STORAGE_KEY,character.id,setShowRolls);
		}
	}

	// On component mount, initialize showActions state based on localStorage
	useEffect(() => {
		async function initializeShowActions() {
			const isInList = await isIDInList(LOCAL_STORAGE_KEY,character.id);
			setShowRolls(isInList);
		}

		initializeShowActions();
	}, [character.id]);

	return (
		<RollProvider value={{
				pathObj,
				rollArray,
				character,
				sendResultToHistory,
				showRolls,
				autoExpand,
				propDef}
			}>
			<>
				<ButtonExpand onClick={()=>toggleActions()} label={"Rolls "+(showRolls?"⇘":"⇒")} />
				<RenderRollsByTypeComponent/>
			</>
		</RollProvider>
	);
}

export default RollArray;