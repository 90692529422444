import EditTextComponent from '../EditText/EditText.jsx';
import ProficiencyDropdownComponent from '../ProficiencyDropdown/ProficiencyDropdown.jsx';
import { propertyDefs } from '../../PropertyDefs.js';

export default function editStats(groupedProperties,updateValue,pathObj) {
	const statblockpath = {"path":[...pathObj.path,"statblock"]};
	return Object.keys(groupedProperties).map(category => {
		return (
			<div className='edit-stat-row' key={category}>
				<div className='edit-stat-row-header'>{category}</div>
				{groupedProperties[category].map((entry, index) => {
					if (entry.type == "profLevel")
					{
						// Special dropdown for setting 0, proficient or expert
						return ( 
							<ProficiencyDropdownComponent 
								label={entry.name}
								key={category + index + entry.id} 
								onUpdateValue={updateValue} 
								pathObj={{"path":statblockpath.path, "propertyID":entry.id}} 
								property={entry.id} 
								propertyDef={entry} 
							/>
						)
					}
					else
					{
						return (
							<EditTextComponent
								label={entry.name}
								key={category + index + entry.id} 
								onUpdateValue={updateValue} 
								pathObj={{"path":statblockpath.path, "propertyID":entry.id}} 
								property={entry.id} 
								propertyDef={entry} 
								type={typeof(propertyDefs.defaultStatblock[entry.id])}
								className={entry.id==="special" ? "edit-stat-field-full" : "edit-stat-field"}
							/>
						)
					}
				})}
			</div>
		);
	});
}
