import React, { useEffect, useContext } from 'react';
import { SearchContext } from './contexts/SearchContext';
import Modal from './components/Modal/Modal';

const KeyboardManager = () => {
    const { openSearch } = useContext(SearchContext);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.ctrlKey && event.shiftKey && event.key === 'F') {
                openFindMenu();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        // Cleanup the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [openSearch]);

    const openFindMenu = () => {
        openSearch(); // Open the modal
    };

    return <Modal />; // Render the modal
};

export default KeyboardManager;
