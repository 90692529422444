import React, { useRef, useState, useEffect } from 'react';
import './Sidebar.css';
import { useDatabase } from '../../DatabaseProvider.jsx';
import SidebarButtonComponent from './SidebarButton.jsx';
import { smartLog } from '../../util/smartLog.js';
import VisibilityController from './VisibilityController.js';
import * as dragUtil from '../../util/dragUtil.js';
import { buildHierarchy, flattenHierarchy } from './buildHierarchy.js';
import { categoryList } from '../../PropertyDefs.js';
import ButtonAdd from '../Buttons/ButtonAdd.jsx';
import ButtonExpand from '../Buttons/ButtonExpand.jsx';
import decimalToFraction from '../../util/decimalToFraction.js';
import { GetDateAgo } from '../../util/DaysAgo.js';
// List of entry buttons in the sidebar (all entries for selected category)
// Draggable to re-arrange

const EntryListComponent = ({ orderInfo, currentEnt, validEntries, setEntry, currentCat }) => {

	const db = useDatabase();
	const { visibilityInfo, collapseAll, expandAll, toggleVisibility } = VisibilityController(orderInfo, currentCat);

	useEffect(()=> {
		// console.log(visibilityInfo)
	}, [visibilityInfo])
	
	const addEntry = () => {
		const newEntry = db.addEntry(currentCat);
		setEntry(currentCat, newEntry.id);
	};

	function toggleChildren(entryID)
	{
		toggleVisibility(entryID);
	}

	const isAncestorHidden = (entryId, visibilityInfo, orderInfo) => {
		let currentEntryId = entryId;
		while (currentEntryId) {
			if (visibilityInfo.includes(currentEntryId)) {
				return true;
			}
			const parentEntry = orderInfo.find(e => e.id === currentEntryId);
			currentEntryId = parentEntry ? parentEntry.parentID : null;
		}
		return false;
	};
	
	const catDef = categoryList.find(o=>o.id===currentEnt.categoryID);
	const prefix = catDef.prefix;

	const renderEntryList = () => {
		if (visibilityInfo === null) {
			return null;
		}
	
		const hierarchy = buildHierarchy(orderInfo);
		const flattenedHierarchy = flattenHierarchy(hierarchy);
	
		return flattenedHierarchy.filter(entry => {
			// On mobile, always hide non-current titles category and current entry only
			if (db.isPortrait && currentEnt.entryID && entry.id !== currentEnt.entryID) {
				return false;
			}
			return !isAncestorHidden(entry.parentID, visibilityInfo, orderInfo);
		}).map((entry, index) => {

			// If this entry is the hovered one, force the hover style since dragging halts normal hover CSS behavior
			const hoverSpacerClass = (hoveredElement && hoveredElement.type==="spacer" && hoveredElement.id===entry.id) ? "drag-hover" : "";
			const hoverButtonClass = (hoveredElement && hoveredElement.type==="button" && hoveredElement.id===entry.id) ? "drag-hover" : "";
			const isSelected = entry.id === db.currentEntry.entryID;
			const indentClass = 'indent-'+entry.depth +" "+hoverSpacerClass;
			const fullEntry = validEntries.find(validEntry => validEntry.id === entry.id) || {};
			const orderEntry = orderInfo.find(o => o.id === entry.id);
			let arrow = visibilityInfo.includes(orderEntry.id) ? "▶" : "▼";
			if (!orderEntry.children || orderEntry.children.length == 0)
			{
				// Can not expand
				arrow = "|";
			}

			const expandMySpacer = hoveredElement && hoveredElement.id===entry.id;
			//Filtered-out entries come in as blank objects
			if (Object.keys(fullEntry).length === 0) { return null;}

			let fullEntryName = fullEntry.name;
			let pref = "";
			if (prefix)
			{
				// Dig out the matching prefix
				switch (prefix)
				{
					case "cr":
						// That's in the statblock of a monster
						const monst = db.data["monsters"][entry.id];
						if (monst)
						{
							const value = monst.statblock.cr;
							if (value)
							{
								pref = formatCR(value);
							}
						}
						break;
					case "day":
						const currentDay = db.data.vars.currentDay.value;
						const timelineentry = db.data["timeline"][entry.id];
						if (timelineentry) {
							const value = timelineentry.day;
							if (value) {
								const ago = GetDateAgo(currentDay, timelineentry.day, true);
								pref = ago;
							}
						}
						break;
					default:
						console.log("No EntryList handler for prefix",prefix);
						break;
				}
			}

			function formatCR(cr)
			{
				return " (CR "+decimalToFraction(cr)+")";
			}

			return (
				<React.Fragment key={entry.id}>
					<SidebarButtonComponent
						key={entry.id + index}
						entry={entry}
						indentClass={indentClass}
						hoverButtonClass={hoverButtonClass}
						isSelected={isSelected}
						toggleChildren={toggleChildren}
						setEntry={setEntry}
						currentCat={currentCat}
						fullEntryName={fullEntryName}
						arrow={arrow}
						prefix={pref}
						onDragStart={onDragStart}
						onDragEnd={onDragEnd}
						onDrop={(e) => onDropOnButton(e,entry)}
						onDragEnter={onDragEnterButton}
						onDragLeave={onDragLeaveButton}
					/>
					{/* Drop target after each button. Dropping will move dragged entry to after the associated entry */}
					{isDragging && expandMySpacer && 
						<div
							className={"drop-target "+hoverSpacerClass}
							onDrop={(e) => onDropOnSpacer(e, entry)}
							onDragOver={dragUtil.handleDragOverSpacer}
							onDragEnter={(e) => onDragEnterSpacer(e, entry)}
							onDragLeave={(e) => onDragLeaveSpacer(e, entry)}
						></div>
					}
				</React.Fragment>
			);
		});
	};

	const [hoveredElement, setHoveredElement] = useState(null);
	const [isDragging, setIsDragging] = useState(false);
	
	const onDragStart = (e, entry) => dragUtil.handleDragStart(e, entry, setIsDragging);
    const onDragEnd = (e) => dragUtil.handleDragEnd(e, setIsDragging, setHoveredElement);
    const onDragEnterButton = (e, entry) => dragUtil.handleDragEnterButton(e, entry, setHoveredElement);
    const onDragLeaveButton = (e, entry) => dragUtil.handleDragLeaveButton(e, entry, setHoveredElement);
    const onDragEnterSpacer = (e, entry) => dragUtil.handleDragEnterSpacer(e, entry, setHoveredElement);
    const onDragLeaveSpacer = (e, entry) => dragUtil.handleDragLeaveSpacer(e, entry, setHoveredElement);
    const onDropOnSpacer = (e, entry) => dragUtil.handleDropOnSpacer(e, entry, setHoveredElement, relocateEntry);
    const onDropOnButton = (e, entry) => dragUtil.handleDropOnButton(e, entry, setHoveredElement, relocateEntry);
	const relocateEntry = (instruction) =>  { dragUtil.relocateEntry(instruction,db,currentCat,smartLog); }

	return (
		<>
			<div className="entry-list-controls">
				<ButtonAdd onClick={addEntry} label={"[+]"}/>
				<ButtonExpand onClick={expandAll} label={"Expand ⇣"}/>
				<ButtonExpand onClick={collapseAll} label={"Collapse ⇡"}/>
			</div>
			<div className='entry-list'>
				{renderEntryList()}
			</div>
		</>
	);
};

export default EntryListComponent;