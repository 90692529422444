import {useReorder} from "./reorderRollButtons.jsx";
import { useDatabase } from '../../DatabaseProvider.jsx';
import Roll from "./Roll.jsx";
import { useRollContext } from '../../contexts/Roll/RollContext.jsx';

// Build a roll button from each entry in the roll array. Each one contains controls for re-ordering them within their category

export function buildRollButtons(rollCategoryArrays)
{
	const {rollArray, character, sendResultToHistory } = useRollContext();

	const reorder = useReorder();

	const db = useDatabase();

	// Build a list of Roll buttons for each category 
	if (rollArray)
	{
		rollArray.forEach((id, index) => {
			const rollFromDB = { ...db.data.rolls[id.id] };
			rollFromDB.rollerName = character.name;
			rollFromDB.roller = character;
		
			// Determine the action type, defaulting to 'trait' if it's not specified
			const actionType = rollFromDB.action || 'trait';
			// Push a roll button into the array for this action type 
			if (rollCategoryArrays[actionType]) {
				rollCategoryArrays[actionType].push(
					<Roll 
						key={`${character.id}-${index}`} 
						rollDef={rollFromDB} 
						sendResultToHistory={sendResultToHistory} 
						character={character}
						reorder={reorder}
					/>
				);
			}
		})
	}
}
