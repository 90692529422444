// I'm leaving most of these in an ordered array instead of named objects
// It requires an extra .find to grab the proper entry, but I can use their order as they appear in this list for layout
// While the database is entirely named objects (except for listManager sorter),
// most of these properties are used to not only define what to render, but also what 
// order to render them in.

export const GlobalProperties = [
	{
		"name":"name",
		"label":"Database Name",
		"type":"text",
		"tooltip":"The name of the database"
	}
]

export function FindCategory(def)
{
	if (!def)
	{
		console.error("Null catdef")
		return;
	}

	// If it's already an object that's in the category list, pass it back
	if (typeof(def) == "object")
	{
		if (def.categoryID)
		{
			// Only the name was passed
			return categoryList.find(o => o.id === def.categoryID);
		} 
		if (def.categoryDef)
		{
			// The entire def was passed, send it back 
			return def.categoryDef
		}
		return def; 
	}
	const catlist = categoryList.find(o => o.id === def);
	if (!catlist)
	{
		console.log("Can't find category "+def);
		return def;
	}
	return catlist;
}

export const statblock = 
[
	{
		"id": "ac",
		"placeholder": "AC",
		"name": "AC",
		"type": "number",
		"tooltip": "Armor Class",
		"clickable": false, // Show the static number instead of allowing a click to roll a random one
		"showMod": true, 
		"category": "Base"
	},
	{
		"id": "hp",
		"placeholder": "HP",
		"name": "HP",
		"type": "number",
		"tooltip": "Hitpoints",
		"clickable": false,
		"showMod": true, 
		"category": "Base"
	},
	{
		"id": "speed",
		"placeholder": 0,
		"name": "Speed",
		"type": "number",
		"tooltip": "Speed",
		"clickable": false,
		"showMod": true, 
		"category": "Base"
	},
	{
		"id": "init",
		"placeholder": "Init",
		"name": "Init",
		"type": "number",
		"tooltip": "Initiative Bonus",
		"clickable": false,
		"showMod": true, 
		"category": "Base"
	},
	{
		"id": "prof",
		"placeholder": 0,
		"name": "Prof",
		"type": "number",
		"tooltip": "Proficiency Bonus",
		"clickable": false,
		"showMod": true, 
		"category": "Base"
	},
	{
		"id": "cr",
		"placeholder": 1,
		"name": "CR",
		"type": "cr",
		"tooltip": "Challenge Rating",
		"clickable": false,
		"showMod": true, 
		"category": "Base"
	},
	{
		"id": "spellsave",
		"placeholder": "10",
		"name": "Spell Save",
		"type": "number",
		"tooltip": "Spell Save",
		"clickable": false,
		"showMod": true, 
		"category": "Base"
	},
	{
		"id": "str",
		"ability": "str",
		"placeholder": 0,
		"name": "STR",
		"type": "number",
		"tooltip": "Strength",
		"clickable": true,
		"showMod": true, 
		"category": "Abilities"
	},
	{
		"id": "dex",
		"ability": "dex",
		"placeholder": 0,
		"name": "DEX",
		"type": "number",
		"tooltip": "Dexterity",
		"clickable": true,
		"showMod": true, 
		"category": "Abilities"
	},
	{
		"id": "con",
		"ability": "con",
		"placeholder": 0,
		"name": "CON",
		"type": "number",
		"tooltip": "Constitution",
		"clickable": true,
		"showMod": true, 
		"category": "Abilities"
	},
	{
		"id": "int",
		"ability": "int",
		"placeholder": 0,
		"name": "INT",
		"type": "number",
		"tooltip": "Intelligence",
		"clickable": true,
		"showMod": true, 
		"category": "Abilities"
	},
	{
		"id": "wis",
		"ability": "wis",
		"placeholder": 0,
		"name": "WIS",
		"type": "number",
		"tooltip": "Wisdom",
		"clickable": true,
		"showMod": true, 
		"category": "Abilities"
	},
	{
		"id": "cha",
		"ability": "cha",
		"placeholder": 0,
		"name": "CHA",
		"type": "number",
		"tooltip": "Charisma",
		"clickable": true,
		"showMod": true, 
		"category": "Abilities"
	},
	{
		"id": "savestr",
		"ability": "str",
		"placeholder": 0,
		"name": "STR",
		"type": "profLevel",
		"tooltip": "Strength Save",
		"clickable": true,
		"category": "Saves"
	},
	{
		"id": "savedex",
		"ability": "dex",
		"placeholder": 0,
		"name": "DEX",
		"type": "profLevel",
		"tooltip": "Dexterity Save",
		"clickable": true,
		"category": "Saves"
	},
	{
		"id": "savecon",
		"ability": "con",
		"placeholder": 0,
		"name": "CON",
		"type": "profLevel",
		"tooltip": "Constitution Save",
		"clickable": true,
		"category": "Saves"
	},
	{
		"id": "saveint",
		"ability": "int",
		"placeholder": 0,
		"name": "INT",
		"type": "profLevel",
		"tooltip": "Intelligence Save",
		"clickable": true,
		"category": "Saves"
	},
	{
		"id": "savewis",
		"ability": "wis",
		"placeholder": 0,
		"name": "WIS",
		"type": "profLevel",
		"tooltip": "Wisdom Save",
		"clickable": true,
		"category": "Saves"
	},
	{
		"id": "savecha",
		"ability": "cha",
		"placeholder": 0,
		"name": "CHA",
		"type": "profLevel",
		"tooltip": "Charisma Save",
		"clickable": true,
		"category": "Saves"
	},
	{
		"id": "acrobatics",
		"ability": "dex",
		"placeholder": 0,
		"name": "Acrobatics",
		"type": "profLevel",
		"tooltip": "Acrobatics",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "animalhandling",
		"ability": "wis",
		"placeholder": 0,
		"name": "Animal Handling",
		"type": "profLevel",
		"tooltip": "Animal Handling",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "arcana",
		"ability": "int",
		"placeholder": 0,
		"name": "Arcana",
		"type": "profLevel",
		"tooltip": "Arcana",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "athletics",
		"ability": "str",
		"placeholder": 0,
		"name": "Athletics",
		"type": "profLevel",
		"tooltip": "Athletics",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "deception",
		"ability": "cha",
		"placeholder": 0,
		"name": "Deception",
		"type": "profLevel",
		"tooltip": "Deception",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "history",
		"ability": "int",
		"placeholder": 0,
		"name": "History",
		"type": "profLevel",
		"tooltip": "History",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "insight",
		"ability": "wis",
		"placeholder": 0,
		"name": "Insight",
		"type": "profLevel",
		"tooltip": "Insight",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "intimidation",
		"ability": "cha",
		"placeholder": 0,
		"name": "Intimidation",
		"type": "profLevel",
		"tooltip": "Intimidation",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "investigation",
		"ability": "int",
		"placeholder": 0,
		"name": "Investigation",
		"type": "profLevel",
		"tooltip": "Investigation",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "medicine",
		"ability": "wis",
		"placeholder": 0,
		"name": "Medicine",
		"type": "profLevel",
		"tooltip": "Medicine",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "nature",
		"ability": "int",
		"placeholder": 0,
		"name": "Nature",
		"type": "profLevel",
		"tooltip": "Nature",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "perception",
		"ability": "wis",
		"placeholder": 0,
		"name": "Perception",
		"type": "profLevel",
		"tooltip": "Perception",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "performance",
		"ability": "cha",
		"placeholder": 0,
		"name": "Performance",
		"type": "profLevel",
		"tooltip": "Performance",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "persuasion",
		"ability": "cha",
		"placeholder": 0,
		"name": "Persuasion",
		"type": "profLevel",
		"tooltip": "Persuasion",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "religion",
		"ability": "int",
		"placeholder": 0,
		"name": "Religion",
		"type": "profLevel",
		"tooltip": "Religion",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "sleightofhand",
		"ability": "dex",
		"placeholder": 0,
		"name": "Sleight of Hand",
		"type": "profLevel",
		"tooltip": "Sleight of Hand",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "stealth",
		"ability": "dex",
		"placeholder": 0,
		"name": "Stealth",
		"type": "profLevel",
		"tooltip": "Stealth",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "survival",
		"ability": "wis",
		"placeholder": 0,
		"name": "Survival",
		"type": "profLevel",
		"tooltip": "Survival",
		"clickable": true,
		"category": "Skills"
	},
	{
		"id": "special",
		"name": "Special",
		"placeholder": "special",
		"type": "text",
		"tooltip": "Special Features",
		"clickable": false,
		"category": "Special",
		"default": ""
	}
]

// Rolls that can be made with proficiency: 1d20 + (prof * proficiencyLevel.value)
export const proficiencyLevel = 
[
	{ "id":"◦", "value":0 },
	{ "id":"⦾", "value":1 },
	{ "id":"⦿", "value":2 },
]

// Automatically include the order array
const defaultNoteObject = 
{
	noteOrder: {
		ids:[]
	}
}

// Each categories represents an array of a certain type of entry that the user can edit. 
// Each categories contents are based on the propertyDefs set by "type"
export const categoryList = [
	{
		"id": "encounters",
		"name": "Encounters",
		"type": "encounterDefProperties",
		"default": {name:"", characters:[], notes:defaultNoteObject},
		"tooltip": "Encounter manager - monster list, turn order, dice rollers."
	},
	{
		"id": "events",
		"name": "Events",
		"type": "eventDefProperties",
		"default": {name:"", notes:defaultNoteObject},
		"tooltip": "Descriptions of narrative quests, historical lore, rules that govern the world, etc."
	},
	{
		"id": "timeline",
		"name": "Timeline",
		"type": "timelineEntryProperties",
		"default": {name:"", day: -9999999, notes:defaultNoteObject},
		"autosort": "day",
		"prefix": "day",
		"tooltip": "Day-by-day history of the world. Give a negative day to report a timeframe in days, weeks, months, or years prior to the var:'Current Day' (i.e. 3 weeks ago (wa), 1005 years ago (ya)).<br><br>Use this section to track session notes, as well."
	},
	{
		"id": "tribes",
		"name": "Tribes",
		"type": "tribeDefProperties",
		"default": {name:"", notes:defaultNoteObject},
		"tooltip": "A culture of people. Not necessarily limited to race or region, geography and family affiliations often create extended community relationships."
	},
	{
		"id": "cities",
		"name": "Cities",
		"type": "cityDefProperties",
		"default": {name:"", notes:defaultNoteObject},
		"tooltip": "A population center. City, town, village, hamlet, or a discrete identifiable exterior region outside of any other population centers."
	},
	{
		"id": "locations",
		"name": "Locations",
		"type": "locationDefProperties",
		"default": {name:"", notes:defaultNoteObject},
		"tooltip": "A discrete location within a population center, usually a building or enclosed site."
	},
	{
		"id": "guilds",
		"name": "Guilds",
		"type": "guildDefProperties",
		"default": {name:"", notes:defaultNoteObject},
		"tooltip": "An organization of disparate people of various cultures from different locations working toward a certain goal."
	},
	{
		"id": "monsters",
		"name": "Monsters",
		"type": "monsterDefProperties",
		"default": { name:"", statblock: { cr: 0 }, notes:defaultNoteObject, rolls:[]},
		"extraFilterBy": "statblock.cr",
		"prefix": "cr",
		"tooltip": "A creature with stats and dice rolls. Can participate in Encounters."
	},
	{
		"id": "rolls",
		"name": "Rolls",
		"type": "rollDefProperties",
		"default": {name:"", notes:defaultNoteObject},
		"tooltip": "Characters can have Rolls attached to them allowing in-app die rolling."
	},
	{
		"id": "vars",
		"name": "Vars",
		"type": "varDefProperties",
		"default": {notes:defaultNoteObject},
		"tooltip": "System variables. At present, only 'Current Day' is used by Timeline."
	}
]

export const propertyDefs = 
{
	"defaultStatblock": {
		"cr": 0,
		"ac": 0,
		"hp": 0,
		"speed": 0,
		"init": 0,
		"prof": 0,
		"special": "",
		"spellsave": 10,
		"str": 0,
		"dex": 0,
		"con": 0,
		"int": 0,
		"wis": 0,
		"cha": 0,
		"savestr": 0,
		"savedex": 0,
		"savecon": 0,
		"saveint": 0,
		"savewis": 0,
		"savecha": 0,
		"athletics": 0,
		"acrobatics": 0,
		"sleightofhand": 0,
		"stealth": 0,
		"arcana": 0,
		"history": 0,
		"investigation": 0,
		"nature": 0,
		"religion": 0,
		"animalhandling": 0,
		"insight": 0,
		"medicine": 0,
		"perception": 0,
		"survival": 0,
		"deception": 0,
		"intimidation": 0,
		"performance": 0,
		"persuasion": 0
	},
	"rollActions": [
		{ "id": "action", "name": "Action", "class": "roll-button-action" },
		{ "id": "bonusaction", "name": "Bonus Action", "class": "roll-button-bonusaction" },
		{ "id": "reaction", "name": "Reaction", "class": "roll-button-reaction" },
		{ "id": "legendaryAction", "name": "Legendary Action", "class": "roll-button-legendaryAction" },
		{ "id": "trait", "name": "Trait", "class": "roll-button-trait" },
		{ "id": "undefined", "name": "---", "class": "roll-button-undefined" }, 
	],
	"rollTypes": [
		{ "id":"simple", 	"name":"", "canRollAdv":true },
		{ "id":"attack", 	"name":"Attack", "canRollAdv":true },
		{ "id":"damage", 	"name":"Damage" },
		{ "id":"recharge", 	"name":"Recharge" },
		{ "id":"ability", 	"name":"Ability", "canRollAdv":true },
		{ "id":"bonus", 	"name":"Bonus" },
		{ "id":"save", 		"name":"Save", "canRollAdv":true },
	],
	"rollResultTypes": [
		{"id":"acid",		"name":"acid damage"},
		{"id":"bludgeoning","name":"bludgeoning damage"},
		{"id":"cold",		"name":"cold damage"},
		{"id":"fire",		"name":"fire damage"},
		{"id":"force",		"name":"force damage"},
		{"id":"lightning",	"name":"lightning damage"},
		{"id":"necrotic",	"name":"necrotic damage"},
		{"id":"piercing",	"name":"piercing damage"},
		{"id":"poison",		"name":"poison damage"},
		{"id":"psychic",	"name":"psychic damage"},
		{"id":"radiant",	"name":"radiant damage"},
		{"id":"slashing",	"name":"slashing damage"},
		{"id":"thunder",	"name":"thunder damage"},
		{"id":"recharge",	"name":"recharge"},
		{"id":"sneakattack","name":"sneak attack damage"},
		{"id":"bonus",		"name":"bonus"},
		{"id":"bonusdamage","name":"bonus damage"},
		{"id":"healing",	"name":"healing"},
		{"id":"sleep",	    "name":"sleep HP"},
	],
	"indentDepths": [
		{
			"id": "indent0",
			"name": "0",
			"type": "readonly",
			"buttonClass": "indent0",
			"tooltip": "No indent"
		},
		{
			"id": "indent1",
			"name": "1",
			"type": "readonly",
			"buttonClass": "indent1",
			"tooltip": "First level indent"
		},
		{
			"id": "indent2",
			"name": "2",
			"type": "readonly",
			"buttonClass": "indent2",
			"tooltip": "Second level indent"
		},
		{
			"id": "indent3",
			"name": "3",
			"type": "readonly",
			"buttonClass": "indent3",
			"tooltip": "Third level indent"
		},
		{
			"id": "indent4",
			"name": "4",
			"type": "readonly",
			"buttonClass": "indent4",
			"tooltip": "Fourth level indent"
		},
		{
			"id": "indent5",
			"name": "5",
			"type": "readonly",
			"buttonClass": "indent5",
			"tooltip": "Fifth level indent"
		}
	],
	"eventTypes": [
		{
			"id": "narrative",
			"name": "Narrative",
			"symbol": "¶",
			"type": "readonly",
			"class": "narrativeText",
			"tooltip": "Description to share with players."
		},
		{
			"id": "quest",
			"name": "Quest",
			"symbol": "✪",
			"type": "readonly",
			"tooltip": "Outline for a story arc that contains reference links to all the people, places and other events related."
		},
		{
			"id": "govern",
			"name": "Governance",
			"symbol": "☈",
			"type": "readonly",
			"tooltip": "Rules that affect how the world operates."
		},
		{
			"id": "history",
			"name": "History",
			"symbol": "⁂",
			"type": "readonly",
			"tooltip": "Lore and history of what came before, informing the current state of the world."
		},
		{
			"id": "reminder",
			"name": "Reminder",
			"symbol": "☑",
			"type": "readonly",
			"tooltip": "GM's tool to make plans for future sessions and stories."
		}
	],
	"timelineTypes": [
		{
			"id": "session",
			"name": "Session",
			"type": "readonly",
			"tooltip": "Gameplay session"
		},
		{
			"id": "world",
			"name": "World",
			"type": "readonly",
			"tooltip": "History outside of gameplay"
		}
	],
	"genders": [
		{
			"id": "male",
			"name": "Male",
			"type": "readonly"
		},
		{
			"id": "female",
			"name": "Female",
			"type": "readonly"
		},
		{
			"id": "non-binary",
			"name": "Non-binary",
			"type": "readonly"
		},
		{
			"id": "other",
			"name": "Other",
			"type": "readonly"
		}
	],
	"structures": [
		{
			"id": "residence",
			"name": "Residence",
			"type": "readonly"
		},
		{
			"id": "business",
			"name": "Business",
			"type": "readonly"
		},
		{
			"id": "campus",
			"name": "Campus",
			"type": "readonly",
			"tooltip": "Mixed living and working space"
		},
		{
			"id": "civil",
			"name": "Civic",
			"type": "readonly",
			"tooltip": "Governmental"
		}
	],
	"guildCategories": [
		{
			"id": "defense",
			"name": "Defense",
			"type": "readonly"
		},
		{
			"id": "exploration",
			"name": "Exploration",
			"type": "readonly"
		},        
		{
			"id": "production",
			"name": "Production",
			"type": "readonly"
		},
		{
			"id": "manufacturing",
			"name": "Manufacturing",
			"type": "readonly"
		},
		{
			"id": "sales",
			"name": "Sales",
			"type": "readonly"
		},
		{
			"id": "service",
			"name": "Service",
			"type": "readonly"
		},
		{
			"id": "civic",
			"name": "Civic",
			"type": "readonly"
		}
	],
	"noteDefProperties": [
		{
			"id": "name",
			"placeholder": "Name",
			"type": "text",
			"class": "heading2",
			"tooltip": "Title for this entry that shows up on the folding entry line (the draggable part)"
		},
		{
			"id": "txt",
			"placeholder": "Text Content",
			"type": "text",
			"tooltip": "Main content"
		},
		{
			"id": "img",
			"name": "Image",
			"type": "image",
			"tooltip": "Link to an image file"
		},
		{
			"id": "mentions",
			"name": "Mentions",
			"type": "links",
			"tooltip": "Array of links to other properties cross-referenced"
		}
	],
	"cityDefProperties": [
		{
			"id": "name",
			"name": "Village",
			"type": "text",
			"class": "heading1",
			"refresh": true,
			"tooltip": "Name of City"
		},
		{
			"id": "desc",
			"placeholder": "Summary",
			"type": "text",
			"tooltip": "Summary"
		},
		{
			"id": "notes",
			"name": "Notes",
			"type": "entries",
			"tooltip": "Array of attached note entries"
		}
	],
	"locationDefProperties": [
		{
			"id": "name",
			"placeholder": "Name",
			"type": "text",
			"class": "heading1",
			"refresh": true,
			"tooltip": "Name of location"
		},
		{
			"id": "cities",
			"name": "Village",
			"type": "cities",
			"tooltip": "Village containing location",
			"filterBy": true
		},
		{
			"id": "purpose",
			"name": "Purpose",
			"type": "structures",
			"tooltip": "Purpose of structure",
			"filterBy": true
		},
		{
			"id": "guild",
			"name": "Alliances",
			"type": "guilds",
			"tooltip": "Guild associated with location",
			"filterBy": true
		},
		{
			"id": "desc",
			"placeholder": "Summary",
			"type": "text",
			"tooltip": "Summary"
		},
		{
			"id": "notes",
			"name": "Notes",
			"type": "entries",
			"tooltip": "Array of attached note entries"
		}
	],
	"tribeDefProperties": [
		{
			"id": "name",
			"placeholder": "Name",
			"type": "text",
			"class": "heading1",
			"refresh": true,
			"tooltip": "Name of tribe"
		},
		{
			"id": "desc",
			"placeholder": "Summary",
			"type": "text",
			"tooltip": "Summary"
		},
		{
			"id": "notes",
			"name": "Notes",
			"type": "entries",
			"tooltip": "Array of attached note entries"
		}
	],
	"guildDefProperties": [
		{
			"id": "name",
			"name": "Guild",
			"type": "text",
			"class": "heading1",
			"refresh": true,
			"tooltip": "Name of the guild"
		},
		{
			"id": "category",
			"name": "Category",
			"type": "guildCategories",
			"tooltip": "Main purpose of the guild",
			"filterBy": true
		},
		{
			"id": "guildhall",
			"name": "Headquarters",
			"type": "locations",
			"tooltip": "Location that serves as the guildhall"
		},
		{
			"id": "guildmaster",
			"name": "Leader",
			"type": "monsters",
			"tooltip": "The person in charge of the guild"
		},
		{
			"id": "desc",
			"name": "Summary",
			"type": "text",
			"tooltip": "The sort of businesses that operate within the guild"
		},
		{
			"id": "notes",
			"name": "Notes",
			"type": "entries",
			"tooltip": "Array of attached note entries"
		}
	],
	"encounterDefProperties":[
		{
			"id": "name",
			"placeholder": "Event",
			"type": "text",
			"class": "heading1",
			"tooltip": "Name of event"
		},
		{
			"id": "characters",
			"name": "Combatants",
			"type": "monsters",
			"tooltip": "Array of participants. A ref to the monster and a small collection of per-encounter customizations like name override, current HP"
		}
	],
	"eventDefProperties": [
		{
			"id": "eventType",
			"name": "Type",
			"type": "eventTypes",
			"tooltip": "Type of event",
			"filterBy": true
		},
		{
			"id": "name",
			"placeholder": "Event",
			"type": "text",
			"class": "heading1",
			"refresh": true,
			"tooltip": "Name of event"
		},
		{
			"id": "desc",
			"placeholder": "Summary",
			"type": "text",
			"tooltip": "Summary"
		},
		{
			"id": "notes",
			"name": "Notes",
			"type": "entries",
			"tooltip": "Array of attached note entries"
		}
	],
	"monsterDefProperties": [
		{
			"id": "name",
			"name": "Name",
			"type": "text",
			"class": "heading1",
			"tooltip": "Name of monster",
			"specialFeature": "parseDNDBeyond"
		},
		{
			"id": "desc",
			"placeholder": "Summary",
			"type": "text",
			"tooltip": "Summary"
		},
		{
			"id": "statblock",
			"name": "Stats",
			"type": "statblock",
			"tooltip": "Stats for combat"
		},
		{
			"id": "rolls",
			"name": "Dice",
			"type": "rolls",
			"tooltip": "Array of attached dice rollers"
		},
		{
			"id": "notes",
			"name": "Notes",
			"type": "entries",
			"tooltip": "Array of attached note entries"
		},
		{ "id": "tribe", "name": "Tribe", "type": "tribes", "tooltip": "Source tribe (indicates race)", "filterBy": true, "sharedRow": "personal" },
		{ "id": "gender", "placeholder": "Gender", "type": "genders", "tooltip": "Identified gender", "filterBy": true, "sharedRow": "personal" },
		{ "id": "guild", "name": "Alliance:", "type": "guilds", "tooltip": "Guild of employment", "filterBy": true, "sharedRow": "personal" },
		{ "id": "home", "name": "Home", "type": "locations", "tooltip": "Home location (apartment, estate)", "filterBy": true, "sharedRow": "personal" },
		{ "id": "work", "name": "Work", "type": "locations", "tooltip": "Work location", "filterBy": true, "sharedRow": "personal" },

	],
	"rollDefProperties": [
		{
			"id": "name",
			"name": "Name",
			"class": "heading1",
			"type": "text",
			"tooltip": "Name of dice roll"
		},
		{
			"id": "shortname",
			"name": "Short Name",
			"class": "heading2",
			"type": "text",
			"tooltip": "Name to display on button"
		},
		{
			"id": "desc",
			"placeholder": "Extra effects",
			"type": "text",
			"tooltip": "Saving throw for target, if applicable"
		},
		{
			"id": "dice",
			"type": "dice",
			"tooltip": "All dice to roll"
		},
		{
			"id": "action",
			"placeholder": "Action Type",
			"type": "rollActions",
			"tooltip": "Type of action consumed by the roll",
			"filterBy": true,
		}
	],
	"timelineEntryProperties": [
		{
			"id": "name",
			"placeholder": "Name",
			"type": "text",
			"class": "heading2",
			"tooltip": "Title for this entry in the timeline"
		},
		{
			"id": "day",
			"name": "Day",
			"placeholder": "Day",
			"type": "daysago",
			"class": "dayEntry",
			"tooltip": "Day of event relative to start of campaign"
		},
		{
			"id": "notes",
			"name": "Notes",
			"type": "entries",
			"tooltip": "Array of attached note entries"
		},
		{
			"id": "eventType",
			"name": "Type",
			"type": "timelineTypes",
			"tooltip": "Type of event",
			"filterBy": true
		}
	],
	"varDefProperties": [
		{
			"id": "name",
			"type": "text",
			"tooltip": "Built-in vars"
		},
		{
			"id": "value",
			"name": "Value",
			"type": "text",
			"tooltip": "Built-in vars"
		},
		{
			"id": "varType",
			"name": "Type",
			"type": "text",
			"tooltip": "Parse to bool or number"
		}
	]
}