import './JumpList.css';
import React, { useEffect } from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';
import ButtonJump from '../Buttons/ButtonJump.jsx';

function JumpListComponent({listArray})
{
	const db = useDatabase();

	function jumpTo(instruction)
	{
		db.setCurrentEntry(instruction);
	}

	const linkbutton = (link,index) => {
		// Look it up so we can show its name
		const category = db.data[link.categoryID];
		const entry = category[link.entryID];
		if (!entry)
		{
			console.warn("No entry",link);
			return null;
		}
		
		const key = link.categoryID+"-"+link.entryID+"-"+index;
		return <ButtonJump key={key} onClick={()=>jumpTo(link)} label={entry.name} />
	}
	
	if (!listArray || listArray.length === 0)
	{
		return null;
	}

	return (
		<div className='used-by-row'>
			<div>Used by:</div>
			{listArray.map((entry, index) => {
				return linkbutton(entry,index)
			})}
		</div>
	)
}
export default JumpListComponent;