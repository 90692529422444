// Modal.jsx
import React, { useState, useEffect, useRef, useContext } from 'react';
import { SearchContext } from '../../contexts/SearchContext.jsx'; // Adjust import as needed
import { useDatabase } from '../../DatabaseProvider.jsx';
import './Modal.css';

const Modal = () => {
    const { isSearchOpen, closeSearch, filter, searchResults, performSearch } = useContext(SearchContext);
    const filterInputRef = useRef(null);
    const db = useDatabase();

	const [destPathObj,setDestPathObj] = useState(null);
	
	useEffect(() => {
        if (isSearchOpen && isSearchOpen.open && filterInputRef.current) 
		{
			const destPathObj = (isSearchOpen && isSearchOpen.destPathObj) ? [...isSearchOpen.destPathObj.path, isSearchOpen.destPathObj.propertyID] : "";
			setDestPathObj(destPathObj);
            filterInputRef.current.focus();
            filterInputRef.current.select();
        }
    }, [isSearchOpen]);

	useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                if (filter !== '') {
                    performSearch('');
                } else {
                    closeSearch();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [filter, performSearch, closeSearch]);

	// Early out
    if (!isSearchOpen || !isSearchOpen.open) 
	{
		return null;
	}

	function handleEntryClick(category,entry)
	{
		const destEntry = {categoryID:category,entryID:entry};
		if (destPathObj)
		{
			db.updateValueByPath(destPathObj,destEntry)
		}
		else
		{
			db.setCurrentEntry(destEntry);
		}
		closeSearch();
	}

	function handleNoteClick(category,entry,note)
	{
		const destEntry = {categoryID:category,entryID:entry,noteID:note};
		if (destPathObj)
		{
			db.updateValueByPath(destPathObj,destEntry)
		}
		else
		{
			db.setCurrentEntry(destEntry);
		}
		closeSearch();
	}

	const searchOutput = (
		<div className="search-results">
			{Object.entries(searchResults).map(([category, entries]) => (
				<div key={category}>
					<div className='search-results-category'>{category}</div>
					{entries.map(entry => (
						<div key={entry.id} className='search-results-button-row'>
							<button className='search-results-button-cat' onClick={() => handleEntryClick(category,entry.id)}>
								{entry.name}
							</button>
							{entry.matchedNotes.map(note => (
								<button className='search-results-button' key={note.id} onClick={() => handleNoteClick(category,entry.id, note.id)}>
									{note.name}
								</button>
							))}
						</div>
					))}
				</div>
			))}				
		</div>
	)

	return (
        <div className="full-modal">
            <input
				ref={filterInputRef}
                type="text"
                placeholder="Filter..."
                value={filter}
                onChange={(e) => performSearch(e.target.value)}
            />
			{searchOutput}
            <button onClick={closeSearch}>Close</button>
        </div>
    );
};

export default Modal;

