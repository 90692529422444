
	// Button that adds new content (usually opening the new entry in the process)
	// Using divs to allow nesting
	const ButtonAdd = ({ onClick, className, label }) => {

		// Append any extra classes
		const fullClassName = 'button-default button-add '+(className ? className : '');

		return (
			<div className={fullClassName} onClick={onClick}>
				{label}
			</div>
		)
	};

	export default ButtonAdd;