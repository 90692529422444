import './EditImage.css'
import React, { useEffect, useState } from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';
import { useRemoteTab } from '../../contexts/RemoteTab/RemoteTabProvider.jsx';
import { useCachedImage } from '../../ImageCacheContext.jsx';
import ImagePickerComponent from '../ImagePicker/ImagePicker.jsx';
import ButtonDelete from '../Buttons/ButtonDelete.jsx';
import ButtonAdd from '../Buttons/ButtonAdd.jsx';
import ButtonAction from '../Buttons/ButtonAction.jsx';

const API_URL = `${import.meta.env.VITE_API_URL}`;

const EditImage = ({ pathObj }) => {

	const db = useDatabase();

	const [imageFileName, setImageFileName] = useState('');
	const [showImagePicker, setShowImagePicker] = useState(false);

	const { sendMessageToRemoteTab } = useRemoteTab();
	const [isDragging, setIsDragging] = useState(false);

	const handleMouseDown = (e) => {
		e.preventDefault(); // Prevent default drag behavior
		setIsDragging(true);
		sendMousePosition(e);
	};

	const handleMouseMove = (e) => {
		if (isDragging) {
			sendMousePosition(e);
		}
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const sendMousePosition = (e) => {
		const rect = e.target.getBoundingClientRect();
		const xPercent = ((e.clientX - rect.left) / rect.width) * 100;
		const yPercent = ((e.clientY - rect.top) / rect.height) * 100;

		// Send these percentages to the remote tab
		const message = { action: 'panTo', xPercent, yPercent };
		sendMessageToRemoteTab(message);
	};

	useEffect(() => {
		const fetchProperty = async () => {
			const entryData = await db.getPropertyByPath(pathObj.path);
			if (entryData && entryData.img) {
				const fileName = entryData.img.split('\\').pop();
				setImageFileName(fileName);
			}
		};
		
		fetchProperty();
	}, [pathObj, db]);

	const srcApiEndpoint = imageFileName ? `${API_URL}/api/image/${encodeURIComponent(imageFileName)}` : '';

	const cachedImageUrl = useCachedImage(srcApiEndpoint);

	function removeImage()
	{
		const message = "Remove image from note?\n\n"+imageFileName;
		if (confirm(message))
		{
			setImageName("");
		}
	}
	
	function clickButton() {
		if (db.editMode) {
			setShowImagePicker(true);
		} else {
			const message = {"imageSrc":srcApiEndpoint};
			sendMessageToRemoteTab(message);
		}
	}

	const buttonLabel = db.editMode ? 'Set Image' : 'Send Image';

	const imageButton = () => {
		// Hide the button in Viewing mode if no image is set
		if (!db.editMode && !srcApiEndpoint) { return null; }
		return (
			<div className='space-between-row'>
				{(!db.editMode && srcApiEndpoint) && <ButtonAction onClick={clickButton} label={buttonLabel}/>}
				{(db.editMode) && <ButtonAdd onClick={clickButton} label={buttonLabel}/>}
				{(db.editMode && srcApiEndpoint) && <ButtonDelete onClick={removeImage} label={"Remove"}/>}
			</div>
		)
	}

	const handleSliderChange = (event) => {
		const zoomLevel = event.target.value;
		// Format the message as you need, for example, as an object
		const message = { action: 'zoom', level: zoomLevel };
		sendMessageToRemoteTab(message);
	};

	function setImageName(name)
	{
		setShowImagePicker(false);
		setImageFileName(name);
		db.updateValueByPath(pathObj,name);
	}

	function zoomSlider()
	{
		return (
			<input 
				type="range" 
				min="0" 
				max="100" 
				defaultValue="0" 
				className="zoom-slider" 
				onChange={handleSliderChange} 
			/>
		)
	}
	
	if (showImagePicker)
	{
		return <ImagePickerComponent onChoose={setImageName} />
	}

	return (
		<div className='image-panel'>
			{imageButton()}
			{srcApiEndpoint && 
				<img 
					src={cachedImageUrl} 
					alt="Image" 
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					onMouseLeave={handleMouseUp} // Add this to handle the case where the mouse leaves the image while dragging
					onDragStart={(e) => e.preventDefault()} // Prevent default drag behavior
				/>
			}
			{srcApiEndpoint && zoomSlider()}
		</div>
	);
};

export default EditImage;
