import './MentionManager.css'
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useDatabase } from '../../DatabaseProvider';
import MentionJumpComponent from './MentionJump';
import MentionEditComponent from './MentionEdit';
import { generateUniqueID } from '../../UniqueID';
import { smartLog } from '../../util/smartLog';

const MentionManagerComponent = ({ pathObj, property, propertyDef, label, onUpdateValue }) => 
{
	// console.log({ pathObj, property, propertyDef, label, onUpdateValue })

	const db = useDatabase();

	const fullPath = [...pathObj.path];
	fullPath.push(pathObj.propertyID)
	const mentionArray = db.getPropertyByPath(fullPath);
	if (!mentionArray) 
	{
		smartLog({cat:"missing"},"No mentions",fullPath);
		return; 
	}

	const editPath = {...pathObj};
	editPath.path = fullPath;
	editPath.propertyID = "fill in with index in array from MentionEdit before sending";

	function addMention()
	{
		// Create a blank mention in the db
		const newMention = {id:generateUniqueID(),categoryID:""};
		db.addMention(fullPath,newMention)
	}

	const stackClass = db.editMode ? 'mention-column' : 'mention-row';

	return (
		<div className={stackClass}>
			{db.editMode && <button className='mention-button-add' onClick={()=> {addMention()}}>+ Mention</button>}

			{mentionArray.map((mention,index) => (
				<React.Fragment key={mention.categoryID+mention.entryID+index.toString()}>
					{db.editMode ? 
						<MentionEditComponent mention={mention} pathObj={editPath} index={index}/> 
					: 
						<MentionJumpComponent mention={mention}/>}
				</React.Fragment>
		 	 ))}
		</div>
	);
};

export default MentionManagerComponent;
