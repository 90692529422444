import './EditDice.css';
import React, { useEffect, useState } from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';
import EditTextComponent from '../EditText/EditText.jsx';
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown.jsx';
import { generateDropdownOptions } from '../Entry/generateDropdownOptions.js';
import { propertyDefs } from '../../PropertyDefs.js';
import { generateUniqueID } from '../../UniqueID.js';
import JumpListComponent from '../JumpList/JumpList.jsx';

function EditDiceComponent({ pathObj, currentEntryPath }) {

	const db = useDatabase();

	const [rollDef, setRollDef] = useState(db.data[currentEntryPath.categoryID][currentEntryPath.entryID]);

	// Initialize rollDef from db
	useEffect(() => {
		const entryData = db.data[currentEntryPath.categoryID][currentEntryPath.entryID];
		setRollDef(entryData ? entryData : { dice: [] });
	}, [db, currentEntryPath.categoryID, currentEntryPath.entryID]);

	useEffect(() => {
		// console.log("rollDef",rollDef)
	}, [rollDef])
	
	function updateDiceArray(updatedDice) {
		const updatedRollDef = JSON.parse(JSON.stringify(rollDef));
		updatedRollDef.dice = updatedDice;
		// Update the database
		const finalPath = [...pathObj.path];
		db.updateDB(finalPath, updatedRollDef);
	}

	function addRoll() {
		const newRoll = {id: generateUniqueID(), rollType: '', count: 1, die: 20, mod: 0};
		const diceArray = JSON.parse(JSON.stringify((rollDef.dice || [])));
		diceArray.push(newRoll);
		updateDiceArray(diceArray);
	}

	function deleteRoll(index) {
		const diceArray = JSON.parse(JSON.stringify((rollDef.dice || [])));
		diceArray.splice(index, 1);
		updateDiceArray(diceArray);
	}

	function parseDice(diceDef, index, value) {
		if (!value) { return; }
		var match = value.match(/(\d+)d(\d+)([+-]\d+)?/);
		if (match) {
			const count = Number.parseInt(match[1]);
			const die = Number.parseInt(match[2]);
			const mod = match[3] ? Number.parseInt(match[3]) : 0;
			let resultType = "";
			const type = value.split(/[\s]/);
			if (type.length > 1) {
				const potentialType = type[1];
				if (potentialType) {
					const matches = [];
					for (var t = 0; t < propertyDefs.rollResultTypes.length; t++) {
						const tp = propertyDefs.rollResultTypes[t];
						if (tp.id.toLowerCase().startsWith(potentialType.toLowerCase())) {
							matches.push(tp.id);
						}
					}
					if (matches.length === 1) {
						resultType = matches[0];
					}
				}
			}
			if (count) { diceDef.count = count; }
			if (die) { diceDef.die = die; }
			if (mod) { diceDef.mod = mod; }
			if (resultType) { diceDef.resultType = resultType; }
			const updatedDiceArray = [...rollDef.dice];
			updatedDiceArray[index] = diceDef;
			updateDiceArray(updatedDiceArray);
		}
	}

	function findMonstersByRollId(data, rollId) {
		const result = [];
		const monsters = data.monsters;
	
		Object.keys(monsters).forEach(monsterId => {
			const monster = monsters[monsterId];
			if (monster && monster.rolls)
			{
				if (monster.rolls.some(roll => roll.id === rollId)) {
					result.push({ categoryID: "monsters", entryID: monsterId });
				}
			}
		});
	
		return result;
	}

	function jumpList()
	{
		// NOTE: Originally, the IDs of all monsters who use the roll were stored in the DB
		// But as long as this lookup doesn't take too long, there's no need to store
		// Instead, build the list at run-time without saving the data to the db
		const usedByArray = findMonstersByRollId(db.data,rollDef.id);
		if (usedByArray.length > 0)
		{		
			return <JumpListComponent 
				listArray={usedByArray}
			/>
		}

		return null;
	}

	function diceEditor(diceDef, index) {
		const propertyDefparsed = { placeholder: "xdy+m type" }
		const key = diceDef.id + index;
		const mod = diceDef.mod === 0 ? "" : (diceDef.mod > 0 ? "+" + diceDef.mod : diceDef.mod);
		const result = diceDef.resultType ? " " + diceDef.resultType : "";
		const parsedDisplay = diceDef.count + "d" + diceDef.die + mod + result;
		const propertyOptions = generateDropdownOptions({ propertyDefArray: propertyDefs.rollTypes })
		const label = "";
		const value = diceDef.rollType;
		const propDef = { placeholder: "roll type" }

		const resultlabel = "";
		const resultvalue = diceDef.resultType;
		const resultOptions = generateDropdownOptions({ propertyDefArray: propertyDefs.rollResultTypes })
		const resultpropDef = { placeholder: "result type" };

		function setRollType(diceDef,index,value)
		{
			const updatedDiceArray = [...rollDef.dice];
			const roll = updatedDiceArray[index];
			roll.rollType = value;
			updateDiceArray(updatedDiceArray);
		}

		function setResultType(diceDef, index, value) {
			const updatedDiceArray = [...rollDef.dice];
			const roll = updatedDiceArray[index];
			roll.resultType = value;
			updateDiceArray(updatedDiceArray);
		}

		return (
			<div key={key} className='dice-row'>
				<SearchableDropdown
					className='roll-dropdown'
					key={key + "drop"}
					label={label}
					initialValue={value}
					options={propertyOptions}
					onUpdateValue={(pathObj, value) => { setRollType(diceDef, index, value) }}
					propDef={propDef}
				/>
				<EditTextComponent
					className='die-input'
					simpleCallback={(value) => { parseDice(diceDef, index, value) }}
					propertyDef={propertyDefparsed}
				/>
				{db.editMode && <button className='delete-button' onClick={() => deleteRoll(index)}>{'X'}</button>}
				{(diceDef.rollType === "damage" || diceDef.rollType === "bonus") && 
					<SearchableDropdown
						className='roll-dropdown'
						key={key + "result"}
						label={resultlabel}
						initialValue={resultvalue}
						options={resultOptions}
						onUpdateValue={(pathObj, value) => { setResultType(diceDef, index, value) }}
						propDef={resultpropDef}
					/>}
				<div className='die-full'>{parsedDisplay}</div>
			</div>
		)
	}

	return (
		<div key={currentEntryPath.entryID + pathObj.propertyID} id="all-dice-stack" className='dice-editor-stack'>
			{db.editMode && <button className='button-add' onClick={addRoll}>+ Roll</button>}
			{rollDef.dice && rollDef.dice.map((entry, index) => {
				return diceEditor(entry, index);
			})}
			{jumpList()}
		</div>
	);
}

export default EditDiceComponent;
