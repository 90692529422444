import './Note.css';
import React, { useEffect, useRef, useState } from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';
import { propertyDefs } from '../../PropertyDefs.js';
import EditText from '../EditText/EditText.jsx';
import EditImage from '../Image/EditImage.jsx';
import MentionManagerComponent from '../Mention/MentionManager.jsx';
import ButtonDelete from '../Buttons/ButtonDelete.jsx';
import * as dragUtil from '../../util/dragUtil.js';

const EditNote = ({ pathObj, noteDef, index, masterExpand, reorderCallback, deleteCallback }) => {

	const db = useDatabase();
	const noteDefProps = propertyDefs.noteDefProperties;

	const [expanded, setExpanded] = useState(true);

	useEffect(()=> {
		if (masterExpand==="exp") { setExpanded(true); }
		if (masterExpand==="col") { setExpanded(false); }
	}, [masterExpand])

	function updateValue(pathObj, value) {
		const finalPath = [...pathObj.path, pathObj.propertyID];
		db.updateDB(finalPath, value);
	}

	function deleteThisNote()
	{
		const message = "Delete Note? Cannot be undone\n\n"+noteDef.name+(noteDef.txt ? "\n"+noteDef.txt?.substring(0,40)+"..." : "");
		if (confirm(message))
		{
			deleteCallback(noteDef.id);
		}
	}

	const renderEditor = (propDef, pathObj) => {
		// Modified to take pathObj directly
		switch (propDef.type) {
			case "text":
				return <EditText key={propDef.id} onUpdateValue={updateValue} pathObj={pathObj} property={propDef.id} propertyDef={propDef} />;
			case "image":
				return <EditImage key={propDef.id} onUpdateValue={updateValue} pathObj={pathObj} property={propDef.id} propertyDef={propDef} />;
			case "links":
				return <MentionManagerComponent key={propDef.id} onUpdateValue={updateValue} pathObj={pathObj} property={propDef.id} propertyDef={propDef}/>
			default:
				console.warn("No handler for EditNote type:", propDef.type);
				return null;
		}
	};

	// Filter props for the left and right columns
	const textAndMentionsProps = noteDefProps.filter(prop => prop.type === "text" || prop.type === "links");
	const imageProps = noteDefProps.filter(prop => prop.type === "image");

	// Function to generate pathObj for a property
	const createPathObj = (propDef) => ({
		...pathObj,
		path: [...pathObj.path, noteDef.id],
		propertyID: propDef.id,
	});

	function renderAnEditor(id)
	{
		const propDef = noteDefProps.find(o=>o.id===id);
		if (!propDef)
		{
			console.log("Can't find propDef: "+id);
		}
		else
		{
			const pathObj = createPathObj(propDef);
			return renderEditor(propDef, pathObj);
		}
		return null;
	}

	const image = (
		<div className='edit-note-image'>
			{imageProps.map((propDef) => {
				return renderEditor(propDef, createPathObj(propDef));
			})}
		</div>
	)

	const delayHideRef = useRef();
	const [hoveredElement, setHoveredElement] = useState(null);
	const [isDragging, setIsDragging] = useState(false);
	const [showDropTargets,setShowDropTargets] = useState(false);


	useEffect(()=>{
		if (delayHideRef.current)
		{
			clearTimeout(delayHideRef.current);
		}
	},[showDropTargets])


	function delayHideDropTargets()
	{
		if (delayHideRef.current)
		{
			clearTimeout(delayHideRef.current);
		}
		delayHideRef.current = setTimeout(()=> {
			setShowDropTargets(false);
		},10)
	}

	function tryToShowDropTargets(e)
	{
		const droppedEntryId = e.dataTransfer.getData("text/plain");
		// Can't drop on self
		if (droppedEntryId != noteDef.id)
		{
			setShowDropTargets(true);
		}
	}
	
	const onDragStart = (e, entry) => dragUtil.handleDragStart(e, entry, setIsDragging);
    const onDragEnd = (e) => delayHideDropTargets();
    const onDragEnter = (e, entry) => tryToShowDropTargets(e);
    const onDragLeave = (e, entry) => {delayHideDropTargets(); }
    const onDrop = (e, entry, dir) => handleDropOnButton(e, entry, dir);

	const handleDropOnButton = (e, targetEntry, dir) => {

		e.preventDefault();
		setShowDropTargets(false);
		const droppedEntryId = e.dataTransfer.getData("text/plain");
		setHoveredElement(null);
		const instruction = {
			src:droppedEntryId,
			dest:targetEntry.id,
			dir:dir
		}
		reorderCallback(instruction);
	};

	const handleDragOver = (e) => {
		// Must ignore in order to allow drop
		e.preventDefault();
		if (delayHideRef.current)
		{
			clearTimeout(delayHideRef.current);
		}
	};

	const name = renderAnEditor("name");
	const txt = renderAnEditor("txt");
	const mentions = renderAnEditor("mentions");
	const buttonLabel = expanded ? "▼" : "▶";
	const buttonDelete = <ButtonDelete onClick={()=>deleteThisNote()} label={"Delete Note"} />
	// Expand toggle not only toggles, it also serves as the drag handle for drag-and-drop sorting
	const expandToggle = 
		<div 
			onDragStart={(e) => onDragStart(e, noteDef)}
			onDragEnd={(e) => onDragEnd(e, noteDef)}
			onDragOver={handleDragOver}
			onDragEnter={(e) => onDragEnter(e, noteDef)}
			onDragLeave={(e) => onDragLeave(e, noteDef)}
			draggable={db.editMode} 
			className="expand-button" 
			onClick={() => toggleExpand()}
		>
			{buttonLabel}
		</div>
	
	function toggleExpand()
	{
		setExpanded(!expanded);
	}

	function setupDropTarget(dir)
	{
		return <div 
					className='droppable-target'					
					onDragOver={handleDragOver}
					onDrop={(e) => onDrop(e, noteDef, dir)}
				/>
	}
	
	return (
		<div className='edit-note-row'>
			<div className='edit-note-info' onDragOver={handleDragOver} onDragEnter={(e) => onDragEnter(e, noteDef)} onDragLeave={(e) => onDragLeave(e, noteDef)}>
				<div className='droppable-stack'>
					{showDropTargets && setupDropTarget(-1)}
					<div className='collapsible-title'>
						{expandToggle}
						{name}
						{db.editMode && buttonDelete}
					</div>
					{showDropTargets && setupDropTarget(1)}
				</div>
				{expanded && txt}
				{expanded && mentions}
			</div>
			{expanded && image}
		</div>
	);
};

export default EditNote;
