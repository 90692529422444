import { openDB } from 'idb';

const DATABASE_NAME = 'jjmDB';
const STORE_NAME = 'localStorage';
const VERSION = 1;

async function setupDatabase() {
  const db = await openDB(DATABASE_NAME, VERSION, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME);
    },
  });
  return db;
}

export const db = setupDatabase();
