import './Editor.css';
import React, { useEffect } from 'react';
import { useDatabase } from '../../DatabaseProvider.jsx';
import SidebarComponent from '../Sidebar/Sidebar.jsx';
import EntryComponent from '../Entry/Entry.jsx';
import { categoryList } from '../../PropertyDefs.js';
import MainMenu from '../MainMenu/MainMenu.jsx';
import {generateUniqueID} from '../../UniqueID.js'
import { smartLog } from '../../util/smartLog.js';

// Core component of the entire interface.
// Manages the main menu, sidebar and entry component
function Editor() 
{
	// db object includes data (entire database) and udpateDB (function that takes a copy of the db with changes included)
	const db = useDatabase();

	// Converts an array to an object with ids as keys
	const convertArrayToObject = (dataArray) => 
	{
		return dataArray.reduce((obj, item) => 
		{
			obj[item.id] = item;
			return obj;
		}, {});
	};

	function convertToNamedObjects(anonymousArray) 
	{
		const namedObjects = {};
		
		anonymousArray.forEach(entry => 
		{
			// Generate a unique ID for each encounter
			const uniqueID = generateUniqueID();
			// Use that unique ID as the key name for the object
			namedObjects[uniqueID] = 
			{
				id: uniqueID,
				name: entry.name,
				characters: entry.characters
			};
		});
	  
		return namedObjects;
	}

	useEffect(() => {

		if (!db.data) { return; }

		// Sanity check monster rolls
		function hasRollsProperty(obj) {
			return obj.hasOwnProperty("rolls");
		}
		
		// Iterate through the monsters and add the "rolls" property if missing
		Object.entries(db.data.monsters).forEach(([monsterKey, value]) => {
			if (!hasRollsProperty(db.data.monsters[monsterKey])) {
				db.data.monsters[monsterKey].rolls = []; 
				console.log("Added missing roll array to",monsterKey)
			}
			else
			{
				// console.log(db.data.monsters[monsterKey].rolls);
			}
		});
	},[db.data])
		
	// Upgrade to current version
	useEffect(() => 
	{
		// Create a new copy of the database in case we need to upgrade it
		let newDB = { ...db.data };

		// Upgrade from legacy version (each category was an array originally)

		if (db.data && db.data.version < 2)
		{
			// Now each entry in each category is an object named for its id
			categoryList.forEach(catdef => {
				const categoryName = catdef.id;

				if (categoryName === "encounters")
				{
					// Version 1 encounters was a weird format. Convert to namedObject
					const newEncounters = convertToNamedObjects(db.data["encounters"]);
					newDB["encounters"] = newEncounters;
				}
				else
				{
					// Version 1 all other categories were plain arrays. Convert to namedObject for easier access
					const array = newDB[categoryName];
					const newObject = convertArrayToObject(array);
					newDB[categoryName] = newObject;

					// Also update the notes section of each category to object instead of array
					Object.entries(newDB[catdef.id]).forEach(([key, value]) => 
					{
						if (value['notes']) {
							value['notes'] = convertArrayToObject(value['notes']);
							// And while we're at it, dig into each note and replace the absolute image paths with relative
							Object.entries(value['notes']).forEach(([key, note]) => 
							{
								if (note.img)
								{
									note.img = note.img.split('\\').pop();
								}
							});
						}
					});
				}

				// Create a rootOrder object in every listManager per category
				let correspondingListManager = newDB.listManagers[categoryName];
				if (!correspondingListManager)
				{
					newDB.listManagers[categoryName] = [];
					Object.entries(newDB[categoryName]).forEach(([key, value]) => {
						// Just a bunch of blanks
						newDB.listManagers[categoryName].push({id:value.id})
					});
					correspondingListManager = newDB.listManagers[categoryName];
				}

				let rootOrderEntry = correspondingListManager.find(o=>o.id==='rootOrder');
				if (!rootOrderEntry) 
				{
					console.log("Creating rootOrder for",categoryName);
					// Find top-level entries and construct rootOrder
					const topLevelEntries = correspondingListManager.filter(entry => !entry.parentID);
					const topLevelIds = topLevelEntries.map(entry => entry.id);
					rootOrderEntry = { id: 'rootOrder', children: topLevelIds };
					correspondingListManager.push(rootOrderEntry);
		
					// Update parentID for top-level entries
					topLevelEntries.forEach(entry => {
						entry.parentID = 'rootOrder';
					});
				}
			});

			// Sanity check listManager for old legacy junk that used to sneak in
			categoryList.forEach(catdef => {
				newDB.listManagers[catdef.id].forEach((listmanEntry) => {
					const validProps = new Set(['id', 'parentID', 'children']);
					Object.keys(listmanEntry).forEach(prop => {
						// If the property is not valid, delete it
						if (!validProps.has(prop)) {
							console.log("How did",prop,"show up in the list manager?");
							delete listmanEntry[prop];
						}
					});
				})
			});
		}
		
		if (db.data && db.data.version < 3)
		{
			// Version 3 introduced a noteOrder object on every entry to define the order in which notes are listed
			// Similar to listManager, but there's no nesting, and the list only applies to that entry's notes
			categoryList.forEach(catdef => {
				const categoryName = catdef.id;
				const categoryObject = newDB[categoryName];
				if (categoryObject)
				{
					Object.values(categoryObject).forEach((entry) => {
						if (entry.notes) {
							if (!entry.notes.noteOrder) {
								const notesIDs = [];
								Object.values(entry.notes).forEach((note) => {
									if (note.id != "noteOrder") {
										notesIDs.push(note.id)
									}
								});

								entry.notes.noteOrder = { id: "noteOrder", ids: notesIDs };
							}
						}
					});
				}				
			});
		}

		const currentVersion = 3
		if (newDB.version < currentVersion)
		{
			// Update the version to prevent this code from running again
			newDB.version = currentVersion;
			db.updateDB(null,newDB);
		}

	}, [db.data?.version]); // Only re-run if version changes
	
	if (!db.data) {
		return <div>Loading...</div>;
	}

	return (
		<div id='main-editor'className='main-editor'>
			<MainMenu />
			<div id='sidebar-and-entry' className='sidebar-and-entry'>
				<div className="sidebar">
					<SidebarComponent />
				</div>
				{db.currentEntry && db.currentEntry.entryID && (
					<EntryComponent 
						currentEntryPath={db.currentEntry}
					/>
				)}
			</div>
		</div>
	);
}

export default Editor;
