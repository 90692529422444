export default function scrollToNote(selectedNoteID) {
    if (!selectedNoteID) { return; }
    const observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
            if (mutation.addedNodes.length > 0) {
                const noteElement = document.getElementById(`note-${selectedNoteID}`);
                if (noteElement) {
                    // Delay the scroll action to give all the elements time to build
                    requestAnimationFrame(() => {
                        setTimeout(() => {
                            noteElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }, 50); 
                    });
                    observer.disconnect(); // Stop observing after scrolling
                    break;
                }
            }
        }
    });

    observer.observe(document.querySelector('.entry-notes'), { childList: true, subtree: true });
	return () => observer.disconnect(); // Clean up on unmount
}
