import React, { useState, useEffect } from 'react';

const useJsonFileList = () => {
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchFiles = async () => {
			try {
				const response = await fetch('/api/jsonList');
				const data = await response.json();
				setFiles(data);
				setLoading(false);
			} catch (err) {
				setError(err);
				setLoading(false);
			}
		};

		fetchFiles();
	}, []);

	return { files, loading, error };
};

export default useJsonFileList;