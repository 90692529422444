import React, { useState, useRef, useEffect } from 'react';
import './NumberModal.css'; // You should define some basic styles for the modal
import ButtonAction from '../Buttons/ButtonAction';

const NumberModal = ({ numberDef, onConfirm, onCancel }) => {

	const [currentTextValue, setCurrentTextValue] = useState(numberDef.value || numberDef.chardef.note || "");
	const [currentValue, setCurrentValue] = useState(numberDef.value);
	const [modifiedValue, setModifiedValue] = useState(numberDef.value);
	const [isResetRequested, setIsResetRequested] = useState(false);
	const [enterPressed, setEnterPressed] = useState(false);

	function updateCurrentTextValue(e) {
        setCurrentTextValue(e.target.value); // Update directly with the input value
    }
	
	useEffect(() => {
		if (enterPressed) {
			handleConfirm();
			setEnterPressed(false); // Reset the flag
		}
	}, [enterPressed, modifiedValue, currentTextValue]); // Depend on the value states

	useEffect(() => {
		const handleGlobalKeyDown = (e) => {
			if (e.key === 'Enter') {
				if (document.activeElement && document.activeElement.tagName === 'INPUT') {
					document.activeElement.blur();
				} else {
					setEnterPressed(true); // Set the flag instead of calling handleConfirm directly
				}
			} else if (e.key === 'Escape') {
				// Trigger cancel
				handleCancel();
			}
		};

		// Attach the event listener to the document
		document.addEventListener('keydown', handleGlobalKeyDown);

		// Clean up: Remove event listener when component unmounts
		return () => {
			document.removeEventListener('keydown', handleGlobalKeyDown);
		};
	}, [onConfirm, onCancel]); // Dependencies for useEffect

	// Sanity check default value
	if (numberDef.value == null)
	{
		numberDef.value = 0;
	}

	const textInput = useRef();

	useEffect(()=>{
		if (textInput.current)
		{
			textInput.current.focus();
			textInput.current.select();
		}
	},[textInput])

	const handleIncrease = (amount) => {
		setModifiedValue((prev) => {
			// Convert previous value to a number, ensuring it's not NaN
			const numericPrev = Number(prev);
			if (isNaN(numericPrev)) {
				console.error("Previous value is not a number:", prev);
				return prev; // or return a default value, like 0
			}
	
			// Ensure the resulting value doesn't exceed the maximum
			const newValue = numericPrev < numberDef.max ? numericPrev + amount : numericPrev;
			return newValue;
		});
	};
	

	const handleConfirm = () => {
		if (numberDef.type === "text")
		{
			onConfirm(currentTextValue,numberDef);
		}
		else
		{
			onConfirm(modifiedValue,numberDef);
		}
	};

	const handleCancel = () => {
		onCancel();
	};

	function updateCurrentTextValue(e)
	{
		const value = e.nativeEvent.target.value;
		if (value !== null)
		{
			setCurrentTextValue(value);
		}
	}

	function updateCurrentNumberValue(e)
	{
		const value = Number.parseInt(e.nativeEvent.target.value);
		if (!isNaN(value))
		{
			setModifiedValue(value);
		}
		else
		{
			e.nativeEvent.target.value = numberDef.value;
		}
	}

	function showResetButton()
	{
		if (!numberDef.defaultValue) { return null; }
		if (numberDef.defaultValue === modifiedValue) { return null; }
		return (
			<ButtonAction label={"Reset to "+numberDef.defaultValue} onClick={()=>resetToDefault()}/> 
		)
	}

	function showCurrentValue()
	{
		return (
			<div className='value-field'>
				<div>{stat}</div>
				<div>{currentValue}</div>
				{delta !== 0 && <div>{sign+delta}</div>}
			</div>
		)
	}
	
	useEffect(() => {
		if (isResetRequested) {
			handleConfirm();
			setIsResetRequested(false);
		}
	}, [modifiedValue, isResetRequested]);

	function resetToDefault() {
		setModifiedValue(numberDef.defaultValue);
		setIsResetRequested(true);
	}

	const delta = modifiedValue - currentValue;
	const sign = delta > 0 ? "+":"";

	const stat = numberDef.stat;

	if (numberDef.type === "text") {
        return (
            <div className="modal-backdrop">
                <div className="modal">
                    <div>{stat}</div>
                    <input 
                        ref={textInput} 
                        className='modal-text-input' 
                        value={currentTextValue} // Use directly
                        onChange={updateCurrentTextValue}
                    />
					<div>{currentValue}</div>
					<div className="modal-actions">
						<button onClick={handleConfirm}>OK</button>
						<button onClick={handleCancel}>Cancel</button>
					</div>
				</div>
			</div>
		);
	}
	const pct = numberDef.defaultValue ? (modifiedValue / numberDef.defaultValue).toLocaleString("en", {style: "percent"}) : "";

	return (
		<div className="modal-backdrop">
			<div className="modal">
				{showResetButton()}
				{showCurrentValue()}
				<div className='value-current'>{modifiedValue}</div>
				<div>{pct}</div>
				<div className="number-controls">
					<button onClick={() => handleIncrease(-10)}>-10</button>
					<button onClick={() => handleIncrease(-1)}>-1</button>
					<button onClick={() => handleIncrease(1)}>+1</button>
					<button onClick={() => handleIncrease(10)}>+10</button>
				</div>
				<input 
					className='modal-text-input' 
					value={modifiedValue} 
					onChange={(e)=>{updateCurrentNumberValue(e)}} 
				/>
				<div className="modal-actions">
					<button onClick={handleConfirm}>OK</button>
					<button onClick={handleCancel}>Cancel</button>
				</div>
			</div>
		</div>
	);
};

export default NumberModal;
